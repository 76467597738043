<template>
    <div class="rn-service-area rn-section-gapTop my-5">
        <div class="container">
            <div class="row">
                <div class="col-12 mb--50">
                    <h3 class="title live-bidding-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ this.$t('home_page_onexus.display_text.our_process') }}</h3>
                </div>
            </div>
            <div class="row g-5">

                <!-- <div v-for="(service,index) in serviceList" :key="`service-${index}`" >

                    <div v-if="index < serviceLimit" :class="secriveColumn" >
                        <div class="rn-service-one color-shape-7" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                            <div class="inner">
                                <div class="icon">
                                    <img :src="service.image" alt="Shape">
                                </div>
                                <div class="subtitle">{{ service.subtitle }}</div>
                                <div class="content">
                                    <h4 class="title">
                                        {{service.title }}
                                    </h4>
                                    <p class="description">{{ service.description }}</p>
                                    <router-link class="read-more-button" :to="service.link">
                                        <i class="feather-arrow-right"/>
                                    </router-link>
                                </div>
                            </div>
                            <router-link class="over-link" :to="service.link"></router-link>
                        </div>
                    </div>
                </div> -->

                <template v-for="(service, index) in serviceList"
                          :key="`service-${index}`">
                    <div v-if="index < serviceLimit" :class="serviceColumn">
                        <ServiceCard :service-data="service"/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import ServiceCard from '@/components/service/ServiceCard'

    export default {
        name: 'Description1',
        components: {ServiceCard},
        props: {
            serviceLimit: {
                type: Number,
                default: 4
            },
            serviceColumn: {
                type: String,
                default: 'col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12'
            }
        },
        data() {
            return {
                // serviceList: [],
                serviceList: [
                    {
                        image: require(`@/assets/images/icons/shape-3.png`),
                        subtitle: 'Etape-01',
                        title: "Dépôt du projet",
                        description: `Dites-nous ce que vous recherchez et trouvez votre freelance`,
                        // link: '/connect'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-6.png`),
                        subtitle: 'Etape-02',
                        title: 'Proposition de profil',
                        description: `On s’occupe de la détection des profils et nous vous proposons ensuite les meilleurs pépites pour vos projets`,
                        // link: '/collection'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-2.png`),
                        subtitle: 'Etape-03',
                        title: `Entretien, choix et recrutement `,
                        description: `Rencontrez notre sélection de freelance et choisissez votre freelances.`,
                        // link: '/connect'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-4.png`),
                        subtitle: 'Etape-04',
                        title: `Démarrage du projet`,
                        description: `Démarrez votre projet sans plus attendre`,
                        // link: '/creator'
                    },
                ],
            }
        },
        watch : {
            '$i18n.locale'(locale) {
                this.loadDatatext()
            }
        },
        mounted(){
            this.loadDatatext()
            console.log(this.serviceList)
        },
        methods: {
            loadDatatext(){
                this.serviceList = [
                {
                        image: require(`@/assets/images/icons/shape-3.png`),
                        subtitle: this.$t('home_page_onexus.display_text.steps') + `-01`,
                        title: this.$t('home_page_onexus.display_text.project_submission'),
                        description: this.$t('home_page_onexus.display_text.tell_us'),
                        // link: '/connect'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-6.png`),
                        subtitle: this.$t('home_page_onexus.display_text.steps') + `-02`,
                        title: this.$t('home_page_onexus.display_text.profile_proposal'),
                        description: this.$t('home_page_onexus.display_text.profile_detection'),
                        // link: '/collection'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-2.png`),
                        subtitle: this.$t('home_page_onexus.display_text.steps') + `-03`,
                        title: this.$t('home_page_onexus.display_text.interview_selection'),
                        description: this.$t('home_page_onexus.display_text.meet_freelancers'),
                        // link: '/connect'
                    },
                    {
                        image: require(`@/assets/images/icons/shape-4.png`),
                        subtitle: this.$t('home_page_onexus.display_text.steps') + `-04`,
                        title: this.$t('home_page_onexus.display_text.project_start'),
                        description: this.$t('home_page_onexus.display_text.start_project_now'),
                        // link: '/creator'
                    },
                ]
            }
        },
    }
</script>