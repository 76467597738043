<template>
    <layout>
        <!-- <breadcrumb title="Sign Up" current="Sign Up"/> -->

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5 d-flex justify-content-center ">
                    <div class="col-6">
                        <div class="form-wrapper-one registration-area">
                            <h4>{{ this.$t('login_signup_page.auth.signup') }}</h4>
                            <form @submit.prevent="signup">
                                <div v-if="form_1===true">
                                    <div class="mb-5 ">
                                        <label class="rn-check-box-label d-flex justify-content-center align-items-center gap-3 select-option-role">
                                            <span>{{ this.$t('login_signup_page.auth.select_role') }}</span>
                                            <i class="feather-info" title="Information about freelance and client"></i>
                                        </label>
                                    </div>
                                    <div class="col-12 select-option" :class="{'check-valide': !!error_type }">
                                        <div class="mb-4 ">
                                            <input type="radio" class="rn-check-box-input" v-model="user.type" @change="switchTypeUser" value="client" id="client-check">
                                            <label class="rn-check-box-label" for="client-check">Client</label>
                                        </div>
                                        <div class="mb-3">
                                            <input type="radio" class="rn-check-box-input" v-model="user.type" @change="switchTypeUser" value="freelance" id="freelance-check">
                                            <label class="rn-check-box-label" for="freelance-check">Freelance</label>
                                        </div>
                                    </div>
                                    <div class="mb-5">
                                        <span class="error-message" v-if="!!error_type" >{{ this.$t('mixins.validation.select_item') }}</span>
                                    </div>
                                    <div class="mb-5 d-flex justify-content-end">
                                        <button type="button" class="btn btn-primary mr--15" title="Next" @click="goToNext(2, checkConditionType())" ><i class="feather-arrow-right"></i></button>
                                    </div>
                                </div>
                                
                                <!-- Next form after options selected -->
                                <div v-if="form_2===true">

                                    <!-- If the option is freelance -->
                                    <div class="freelance-form" v-if="user.type==='freelance'">
                                        <div class="mb-5">
                                            <label for="email" class="form-label">{{ this.$t('login_signup_page.fields.email') }}</label>
                                            <input :class="{'check-valide': error_email}" type="email" id="email" v-model="user.email" >
                                            <span v-if="!!error_email" class="error-message">{{ error_messages.email }}</span>
                                        </div>
                                        <div class="mb-5">
                                            <label for="first_name" class="form-label">{{ this.$t('login_signup_page.fields.first_name') }}</label>
                                            <input  :class="{'check-valide': error_first_name}" type="text" id="first_name" v-model="user.first_name" >
                                            <span v-if="!!error_first_name" class="error-message">{{ error_messages.first_name }}</span>
                                        </div>
                                        <div class="mb-5">
                                            <label for="last_name" class="form-label">{{ this.$t('login_signup_page.fields.last_name') }}</label>
                                            <input :class="{'check-valide': error_last_name}" type="text" id="last_name" v-model="user.last_name" >
                                            <span v-if="!!error_last_name" class="error-message">{{ error_messages.last_name }}</span>
                                        </div>
                                        <div class="mb-5  d-flex justify-content-between">
                                            <button type="button" class="btn btn-primary mr--15" title="Previous" @click="goToPrevious(1)" ><i class="feather-arrow-left"></i></button>
                                            <button type="button" class="btn btn-primary mr--15" title="Next" @click="goToNext(3, !!checkPersonalInformation())" ><i class="feather-arrow-right"></i></button>
                                        </div>
                                    </div>
                                    
                                    <!-- If the option is client -->
                                    <div class="freelance-form" v-else >
                                        <div class="mb-5">
                                            <label for="email" class="form-label">{{ this.$t('login_signup_page.fields.email') }}</label>
                                            <input :class="{'check-valide': error_email}"  type="email" id="email" v-model="user.email" >
                                            <span v-if="!!error_email" class="error-message">{{ error_messages.email }}</span>
                                        </div>
                                        <div class="mb-5">
                                            <label for="first_name" class="form-label">{{ this.$t('login_signup_page.fields.company_name') }}</label>
                                            <input :class="{'check-valide': error_company_name}" type="text" id="first_name" v-model="user.company_name" >
                                            <span v-if="!!error_company_name" class="error-message">{{ error_messages.company_name }}</span>
                                        </div>
                                        <div class="mb-5 d-flex justify-content-between">
                                            <button type="button" class="btn btn-primary mr--15" title="Previous" @click="goToPrevious(1)" ><i class="feather-arrow-left"></i></button>
                                            <button type="button" class="btn btn-primary mr--15" title="Next" @click="goToNext(3, !!checkPersonalInformation())" ><i class="feather-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Create password -->
                                <div class="sign-form-3" v-if="form_3===true">
                                    <div class="mb-5">
                                        <label for="password" class="form-label">{{ this.$t('login_signup_page.fields.create_password') }}</label>
                                        <input :class="{'check-valide': error_password}"  type="password" id="password" v-model="user.password" >
                                        <span v-if="!!error_password" class="error-message">{{ error_messages.password }}</span>
                                    </div>
                                    <div class="mb-5">
                                        <label for="password-confirm" class="form-label">{{ this.$t('login_signup_page.fields.check_password') }}</label>
                                        <input :class="{'check-valide': error_password_confirm}" type="password" id="password-confirm" v-model="password" >
                                        <span v-if="!!error_password_confirm" class="error-message">{{ error_messages.password_confirm }}</span>
                                    </div>
                                    <div class="mb-5 ">
                                        <input type="checkbox" class="-input" id="term-condition" @change="term_condition=!term_condition">
                                        <label class="-label" for="term-condition">{{ this.$t('login_signup_page.fields.term_condition') }}</label><br>
                                        <span class="error-message" v-if="error_term_condition">{{ this.$t('mixins.validation.accept_terms') }}</span>
                                    </div>
                                    <div class="mb-5 d-flex justify-content-between">
                                        <button type="button" class="btn btn-primary mr--15" title="Previous" @click="goToPrevious(2)" ><i class="feather-arrow-left"></i></button>
                                        <button  type="submit" class="btn btn-primary mr--15">{{ this.$t('login_signup_page.auth.signup') }}</button>
                                    </div>
                                </div>
                                <router-link to="/login" class="btn btn-primary-alta">{{ this.$t('login_signup_page.auth.login') }}</router-link>
                            </form>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to sign up</h6>
                            <p> Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <Teleport to="#on-loading-process">
            <OnLoading v-if="onProcessing===true"/>
        </Teleport>

    </layout>
    
    
</template>

<script>
import Layout from "@/components/layouts/Layout"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb"
import OnLoading from "@/components/popups/OnLoading.vue"
import { mapActions } from "vuex"
import FieldsMethodValidator from "../mixins/FieldsMethodValidator"

export default {
    name: 'Signup',
    components: {Breadcrumb, Layout, OnLoading},
    mixins: [ FieldsMethodValidator ],
    data(){
        return{
            error_messages: {
                type: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirm: '',
                company_name: '',
            },
            error_type: false,
            error_first_name: false,
            error_last_name: false,
            error_email: false,
            error_password: false,
            error_password_confirm: false,
            error_company_name: false,
            error_term_condition: false,
            password: '',
            user: {
                type: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                company_name: '',
            },
            onProcessing: false,
            form_1: true,
            form_2: false,
            form_3: false,
            term_condition: false,
        }
    },
    methods: {
        ...mapActions(['subscribeUser']),
        async signup(){
            if(this.checkPassword() && this.checkTermCondition() ){
                this.onProcessing = true
                try{
                    const response = await this.subscribeUser(this.user)
                    if(response.status === 201){
                        this.$router.push('/user-profile')
                    }
                }catch(error){
                    alert('Une erreur est survenu, veuillez réessayer')
                    this.onProcessing = false
                }finally{
                    this.onProcessing = false
                }
            }
        },
        switchTypeUser(){
            if(this.user.type === 'freelance'){
                this.user.company_name = ''
            }else{
                this.user.first_name = ''
                this.user.last_name = ''
            }
            this.user.email = ''
            this.user.password = ''
            this.password = ''
        },
        goToNext(form_number, error_exist){
            if(error_exist === false){
                this[`form_${form_number}`] = true
                this[`form_${form_number===3?form_number-2:form_number+1}`] = false
                this[`form_${form_number - 1}`] = false
            }
        },
        goToPrevious(form_number){
            this[`form_${form_number}`] = true
            this[`form_${form_number===1?form_number+2:form_number+1}`] = false
            this[`form_${form_number + 1}`] = false
        },
        checkConditionType(){
            this.error_type = !this.valideFieldsLength('user', 'type')
            return this.error_type
        },
        checkPersonalInformation(){
            this.error_email = !this.valideFieldsRegex('user', 'email', /^[^\s@]+@[^\s@]+\.[^\s@]+$/ )
            if(this.user.type === 'freelance'){
                this.error_first_name = !this.valideFieldsRegex('user', 'first_name', /^[a-zA-Z ']+$/ )
                this.error_last_name = !this.valideUnrequiredField('user', 'last_name', /^[a-zA-Z ']+$/)
                return (this.error_email || this.error_first_name || this.error_last_name)
            }
            this.error_company_name = !this.valideFieldsRegex('user', 'company_name', /^[a-zA-Z0-9 ',]+$/ )
            return (this.error_email || this.error_company_name)
        },
        checkPassword(){
            this.error_password = !this.valideFieldsLength('user', 'password')
            if(!this.error_password){
                if(this.user.password !== this.password){
                    this.error_password_confirm = true
                    this.error_messages.password_confirm = this.$t('mixins.validation.incorrect_password')
                    return false
                }
                this.error_password_confirm = false
                return !this.error_password
            }
            return !this.error_password
        },
        checkTermCondition(){
            if(this.term_condition){
                this.error_term_condition = false
                return true
            }
            this.error_term_condition = true
            return false
        }
    },
    watch : {
        '$i18n.locale'(local){
            if(this.form_1){
                this.checkConditionType()
            }
            if(this.form_2){
                this.checkPersonalInformation()
            }
            if(this.form_3){
                this.checkPassword() || this.checkTermCondition()
            }
        }
    }
}
</script>
<style lang="scss">
.select-option-role{
    user-select: none;
    position: relative;
    i{
        position:absolute;
        bottom: 0; right: 0;
        cursor: pointer;
    }
    i:hover{
        color:#0eaa95;
    }
}
.select-option{
    padding: 1rem 0 0 1rem;
}
</style>