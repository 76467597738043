<template>
    <layout>
        <breadcrumb title="Projets du freelance" current="Projets du freelance"/>

        <div class="rn-product-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-8 custom-product-col">
                        <explore-project-freelance/>
                    </div>
                    <div class="col-lg-4 custom-product-col">
                        <div class="header-right-fixed position-sticky product-notify-wrapper rbt-sticky-top-adjust-four mt--95 mt_md--20 mt_sm--15">
                            <notification-project-freelance/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <share-modal/>

        <report-modal/>

        <placebid-modal/>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import PlacebidModal from '@/components/modal/PlacebidModal'
import ExploreProjectFreelance from '@/components/explore/ExploreProjectFreelance'
import ShareModal from '@/components/modal/ShareModal'
import ReportModal from '@/components/modal/ReportModal'
import NotificationProjectFreelance from '@/components/notification/NotificationProjectFreelance'

export default {
    name: 'ExploreNine',
    components: {
        ReportModal,
        ShareModal,
        ExploreProjectFreelance,
        PlacebidModal,
        Breadcrumb,
        Layout,
        NotificationProjectFreelance
    }
}
</script>

<style lang="scss" scoped>
    .header-right-fixed {
        top: 90px;
    }
</style>