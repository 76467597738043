<template>
    <header class="rn-header haeder-default header--sticky">
        <div class="container">
            <div class="header-inner">
                <div class="header-left">
                    <logo/>
                    <div class="mainmenu-wrapper">
                        <nav class="mainmenu-nav d-none d-xl-block" v-if="isConnected">
                            <Nav/>
                        </nav>
                        <!-- CHOOSE LANGUAGE BUTTON -->
                        <!-- <button class="option" title="Language"><i class="feather-map"></i></button> -->
                    </div>
                </div>
                <div class="header-right">
                    <div class="setting-option header-btn rbt-site-header flex-shrink-0" id="rbt-site-header">
                        <div class="icon-box d-flex gap-3">
                            <!-- <router-link to="/submitproject" target="_blank">
                                <button class="btn btn-primary-alta btn-small">Déposer un projet</button>
                            </router-link> -->
                            
                            <!-- SWITCH LANGUAGE FR / EN -->
                            <div class="dropdown">
                                <button class="btn btn-primary-alta btn-lg dropdown-toggle" type="button" @click="toogleDropdown()" >
                                    <span>
                                        {{ default_language.toUpperCase() }}
                                    </span>
                                </button>
                                <div class="dropdown-menu" ref="dropdownLanguage">
                                    <span class="dropdown-item language-item-select " @click="changeDefaultLanguage('Fr')" >Français</span>
                                    <span class="dropdown-item language-item-select " @click="changeDefaultLanguage('En')" >English</span>
                                </div>
                            </div>
                            
                            <!-- BOOK SLOT -->
                            <a href="https://calendar.app.google/cR1CSqq4w3zD32Us6" target="_blank">
                                <button class="btn btn-primary-alta" >
                                    {{ this.$t('actions.book_slot') }}
                                </button>
                            </a>

                            <!-- LOGIN / SIGNIN -->
                            <!-- <router-link to="/sign-up" v-if="!isConnected" >
                                <button class="btn btn-primary-alta">
                                    <i class="feather-log-in"></i>
                                </button>
                            </router-link> -->
                                
                        </div>

                    </div>
                    <div class="setting-option mobile-menu-bar d-block d-xl-none" v-if="isConnected" >
                        <div class="hamberger">
                            <button class="hamberger-button"
                                    @click="AppFunctions.addClass('.popup-mobile-menu', 'active')">
                                <i class="feather-menu"/>
                            </button>
                        </div>
                    </div>
                    <div class="my_switcher setting-option">
                        <ul>
                            <li>
                                <a href="#"
                                   data-theme="light"
                                   class="setColor light"
                                   @click.prevent="AppFunctions.addClass('body', 'active-light-mode'), AppFunctions.removeClass('body', 'active-dark-mode')">
                                    <img class="sun-image" :src="require(`@/assets/images/icons/sun-01.svg`)"
                                         alt="Sun images">
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                   data-theme="dark"
                                   class="setColor dark"
                                   @click.prevent="AppFunctions.addClass('body', 'active-dark-mode'), AppFunctions.removeClass('body', 'active-light-mode')">
                                    <img class="Victor Image" :src="require(`@/assets/images/icons/vector.svg`)"
                                         alt="Vector Images">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        
        <Teleport to="#on-loading-process">
            <OnLoading v-if="on_processing===true"/>
        </Teleport>

    </header>
</template>

<script>
import Nav from './Nav'
import AppFunctions from '../../../helpers/AppFunctions'
import Logo from "@/components/logo/Logo"
import BACKEND_URL_BASE_API from "@/services/api_url_base"
import { mapState } from 'vuex'
import OnLoading from "@/components/popups/OnLoading.vue"

export default {
    name: 'Header',
    components: {Logo, Nav, OnLoading},
    data() {
        return {
            AppFunctions,
            isMobileSearchActive: false,
            on_processing: false,
            default_language: this.$i18n.locale,
        }
    },
    mounted() {
        const header = document.querySelector('.header--sticky')
        const setStickyHeader = () => {
            const scrollPos = window.scrollY
            if (scrollPos > 50) {
                header.classList.add('sticky')
            } else {
                header.classList.remove('sticky')
            }
        }
        window.addEventListener('scroll', setStickyHeader)
    },
    computed: {
        ...mapState(['user', 'isConnected']),
        urlImageUser(){
            return this.user && this.user.image ? `${BACKEND_URL_BASE_API}/${this.user.image}`:''
        }
    },
    methods: {
        toogleDropdown(){
            this.$refs.dropdownLanguage.classList.toggle('show')
        },
        changeDefaultLanguage(language){
            if(this.default_language != language.toLowerCase()){
                this.default_language = language.toLowerCase()
                this.on_processing = true
                setTimeout(() => {
                    this.$i18n.locale = language.toLowerCase()
                    localStorage.setItem('prefered_language',language.toLowerCase())
                    this.on_processing = false
                }, 500)
            }
            this.toogleDropdown()
        }
    }
}
</script>

<style lang="scss">
.my_switcher *{
    user-select: none;
}
.image-user{
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}
.language-item-select{
    font-size: 1.8rem;
    cursor: pointer;
}
button.dropdown-toggle{
    span{
        margin-right: .5rem;
    }
}

.dropdown-menu.show {
    background-color: #212e48;
    border-radius: 10px;
}
.dropdown-item.language-item-select {
    color: white;
}
</style>