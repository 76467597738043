<template>
    <form ref="form" class="rwt-dynamic-form" id="contact-form">
        
        <!-- FREELANCE PROFIL -->
        <div class="mb-5">
            <label for="profil_type" class="form-label">Quel est le type de profil recherché?</label>
            <input id="profil_type" name="profil_type" type="text" placeholder="Développeur Fullstack" v-model="formData.profil_type">
        </div>
        <!-- TECHNOS -->
        <div class="mb-5">
            <label for="technos" class="form-label">Sur quelle techno?</label>
            <input id="technos" name="technos" type="text" placeholder="Vuejs, Docker" v-model="formData.technos">
        </div>
        <!-- SENIORITE-->
        <div class="mb-5">
            <label for="experience" class="form-label">Combien d'année d'expérience?</label>
            <input id="experience" name="experience" type="text" v-model="formData.experience">
        </div>

        <!-- START DATE -->
        <div class="mb-5">
            <label for="date_start" class="form-label">Quand voulez-vous débuter ?</label>
            <input id="date_start" name="date_start" type="text" v-model="formData.date_start">
        </div>
        
        <div class="row mb-5">
            <!-- NAME -->
            <div class="col-md-6">
                <label for="name" class="form-label">Votre Nom/Société</label>
                <input name="name" id="name" type="text" v-model="formData.name">
                <span v-if="v$.formData.name.$error" class="text-danger mt-2 d-inline-block">
                    {{ v$.formData.name.$errors[0].$message }}
                </span>
            </div>
            <!-- EMAIL -->
            <div class="col-md-6">
                <label for="email" class="form-label">Votre Email</label>
                <input id="email" name="email" type="email" v-model="formData.email">
                <span v-if="v$.formData.email.$error" class="text-danger mt-2 d-inline-block">
                    {{ v$.formData.email.$errors[0].$message }}
                </span>
            </div>
        </div>
        <!-- MESSAGE -->
        <div class="mb-5">
            <label for="message" class="form-label">Avez-vous d'autre description à fournir</label>
            <textarea name="message" id="message" rows="3" v-model="formData.message"></textarea>
            <span v-if="v$.formData.message.$error" class="text-danger mt-2 d-inline-block">
                {{ v$.formData.message.$errors[0].$message }}
            </span>
        </div>

        <button name="submit" type="submit" class="btn btn-primary" @click.prevent="submitForm">Trouver la pépite</button>
        <p v-if="submitted" class="mt-4 font-14 text-success">Message envoyé! Onexus vous remercie de votre confiance</p>
    </form>
</template>

<script>
    import axios from 'axios'
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'FindFreelancerForm',
        data() {
            return {
                v$: useVuelidate(),
                formData: {
                    name: '',
                    email: '',
                    profil_type: '',
                    date_start:'',
                    message: '',
                    technos:'',
                    experience:''
                },
                submitted: false
            }
        },
        validations() {
            return {
                formData: {
                    name: {required},
                    email: {required, email},
                    message: {required}
                }
            }
        },
        methods: {
            submitForm() {
                this.v$.$validate();
                const data = {
                    subject: 'Recherche Freelance',
                    name: this.formData.name,
                    email: this.formData.email,
                    message:this.formData.message,
                    date_start:this.formData.date_start,
                    profil_type:this.formData.profil_type,
                    technos:this.formData.technos,
                    experience:this.formData.experience
                };

                if (!this.v$.$error) {
                    axios({
                        method: 'post',
                        url: 'https://api.onexus.tech/api/sendmail',
                        data,
                    }).then((_res) => {
                        this.submitted = true;
                        this.formData.name = '';
                        this.formData.email = '';
                        this.formData.message = '';
                        this.formData.experience='';
                        this.formData.technos='';
                        this.formData.profil_type='',
                        
                        alert('Message envoyé')
                        this.v$.$reset()
                    }).catch((err) => {
                        console.log(err)
                    });
                } else {
                    console.log('Please fill up the required fields')
                }
            }
        }
    }
</script>