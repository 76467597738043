<template>
    <layout>
        <!-- <breadcrumb title="Liste des Projets" current="Liste des Projets"/> -->

        <div >
            <div class="container">
                <div class="row mt-5 mb-5">
                    <h2>{{ this.$t('client_project_and_create_update_project_page.display_text.project_list') }}</h2>
                </div>
                <div class="row">
                    <div class="col-12 mb-5" v-for="list in {listProjectActive, listProjectWaiting}" :key="list">
                        <div class="table-titl-area d-flex justify-content-between align-items-center mb-5">
                            <div class="d-flex justify-content-center align-items-center gap-5">
                                <i class="feather-calendar"/>
                                <h4 class="m-0" v-if="list===listProjectActive">
                                    {{ this.$t('client_project_and_create_update_project_page.display_text.ongoing_projects') }}
                                </h4>
                                <h4 class="m-0" v-else>
                                    {{ this.$t('client_project_and_create_update_project_page.display_text.pending_projects') }}
                                </h4>
                            </div>

                            <button v-if="list===listProjectActive"  class="btn btn-primary-alta" @click="goToCreateNewProject()">
                                <i class="feather-plus"></i>
                                <span>{{ this.$t('client_project_and_create_update_project_page.actions.add_new_project') }}</span>
                            </button>
                            
                        </div>
                        <div class="box-table table-responsive">
                            <table v-if="list.length" class="table table-responsive project-list">
                                <thead>
                                    <tr>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.project') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.start_date') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.end_date') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.budget') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.payment_type') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.freelances') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.freelances_active') }}</th>
                                        <th>{{ this.$t('client_project_and_create_update_project_page.table.options') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project in list" :key="project">
                                        <td>{{ project.project_name }}</td>
                                        <td>{{ convertDateFormat(project.start_date) }}</td>
                                        <td>{{ convertDateFormat(project.end_date) }}</td>
                                        <td>${{ project.budget }}</td>
                                        <td>{{ project.payment_type }}</td>
                                        <td>{{ project.requested_freelancer_count }}</td>
                                        <td>{{ project.active_freelancer_count }}</td>
                                        <td>
                                            <div class="d-flex justify-content-center align-items-center gap-5">
                                                <i class="feather-eye" @click="selectProjectToView(project)" title="View details" ></i>
                                                <i class="feather-edit" @click="updateProject(project)" title="Update project" ></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else class="col d-flex justify-content-center align-items-center flex-column p-5 gap-3">
                                <i class="feather-alert-octagon"></i>
                                <label class="text-secondary">
                                    {{ this.$t('client_project_and_create_update_project_page.display_text.empty_item') }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <Teleport to="#on-loading-process">
            <OnLoading v-if="onProcessing===true"/>
        </Teleport>

    </layout>
</template>

<script>
import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import OnLoading from "@/components/popups/OnLoading.vue"

import { getClientProjects } from '../services/project_sercive';
import store from '../store'

export default {
    name: 'UpcomingProjects',
    components: {Breadcrumb, Layout, OnLoading},
    data() {
        return {
            onProcessing: false,
            listProjectActive: [],
            listProjectWaiting: [],
        }
    },
    mounted(){
        this.getListProject()
    },
    methods: {
        async getListProject(){
            this.onProcessing = true
            try{
                const response = await getClientProjects()
                if(response.data.projects){
                    response.data.projects.forEach(project => {
                        if(project.active_freelancer_count){
                            this.listProjectActive.push(project)
                        }else{
                            this.listProjectWaiting.push(project)
                        }
                    })
                }
            }catch(error){
                console.log(error)
                this.onProcessing = false
            }finally{
                this.onProcessing = false
            }
        },
        selectProjectToView(project){
            store.state.projectSelected = project
            this.$router.push('project-details')
        },
        updateProject(project){
            store.state.projectSelected = project
            this.$router.push('create-project')
        },
        goToCreateNewProject(){
            store.state.projectSelected = null
            this.$router.push('create-project')
        },
        convertDateFormat(date_to_format){
            const date = new Date(date_to_format)
            const option_format = {year: 'numeric', month:'long', day:'numeric'}
            const formated_date = date.toLocaleDateString(this.$i18n.locale==='fr'?'fr-FR' : 'en-EN',option_format)
            return formated_date
        },
    }
}
</script>

<style lang="scss">
table.project-list{
    color: slategray;
    thead{
        font-size: 2rem;
        font-weight: bold;
        th{
            color: slategray;
        }
    }
    tbody{
        border: 1px solid slategray;
        tr{
            td{
                font-size: 1.8rem;
                text-transform: capitalize;
                color: slategray;
                i{
                    cursor: pointer;
                }
                i:hover{
                    color: #0352e5f1;
                }
            }
            td:hover{
                color: #fff;
            }
        }
        tr:hover{
            color: #fff;
            background: #2637576f;
        }
    }
}
</style>
