<template>
    <layout>
        <breadcrumb :title="this.$t('submit_project_page.display_text.submit_your_project')" :current="this.$t('submit_project_page.display_text.submit_your_project')"/>

        <div class="login-area message-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="connect-thumbnail">
                            <div class="left-image">
                                <img :src="require(`@/assets/images/import/submitProject.webp`)" alt="submit_project">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                        <div class="form-wrapper-one registration-area">
                            <h3 class="mb--30">{{ this.$t('submit_project_page.display_text.tell_us_all') }}</h3>
                            <p>{{ this.$t('submit_project_page.display_text.our_future_suggestion') }}</p>
                            <SubmitProjectForm/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="rn-contact-top-area rn-section-gapTop bg_color--5">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="section-title mb--30 text-center">
                            <h2 class="title">{{ this.$t('submit_project_page.display_text.contact_us') }}</h2>
                            <!-- <p class="description">
                                There are many variations of passages of Lorem Ipsum available, <br>
                                but
                                the majority have suffered alteration.
                            </p> -->
                        </div>
                    </div>
                </div>

                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-headphones">
                                    <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                                    <path
                                        d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z">
                                    </path>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">{{ this.$t('submit_project_page.display_text.contact') }}</h4>
                                <p><a href="tel:+261384474577">+261 38 44 745 77</a></p>
                                <p><a href="tel:+33659788526">+33 6 59 78 85 26</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-mail">
                                    <path
                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                    </path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">{{ this.$t('submit_project_page.display_text.email') }}</h4>
                                <p><a href="mailto:admin@gmail.com">contact@onexus.tech</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12" data-sal="slide-up" data-sal-delay="250" data-sal-duration="800">
                        <div class="rn-address">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-map-pin">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg>
                            </div>
                            <div class="inner">
                                <h4 class="title">{{ this.$t('submit_project_page.display_text.location') }}</h4>
                                <p> Antananarivo, Madagascar</p>
                                <p> Paris, France</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </layout>
</template>

<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
    import ContactForm from "@/components/contact/ContactForm";
    import SubmitProjectForm from "@/components/contact/SubmitProjectForm";

    export default {
        name: 'SubmitProject',
        components: {ContactForm, Breadcrumb, Layout, SubmitProjectForm},
        mixins: [SalScrollAnimationMixin]
    }
</script>