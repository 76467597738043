import { backendApi } from './api_config'

/**
 * @param project : The client provides these informations: project_ame, description, budget, nb_freelances, payment_type, start_date, and end_date to create project
 * @returns It returns the response of the cretion if it was succeed, and error if there were any error.
 */
export const createProject = async (project) => {
    try {
        const response = await backendApi.post('/projects/create', project)
        return response
    } catch (error) {
        return error.response
    }
}

/**
 * @returns It returns the client's list project, and error if there were any error.
 */
export const getClientProjects = async () => {
    try {
        const response = await backendApi.get('/projects/client')
        return response
    } catch (error) {
        return error.response
    }
}
