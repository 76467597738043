<template>
    <div class="rn-product-area rn-section-gapTop">
        <div class="container">
            <div class="row mb--50 align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        Une sélection de freelances
                    </h3>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                    <div class="view-more-btn text-start text-sm-end">
                        <button class="discover-filter-button btn btn-primary"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                data-sal-delay="150"
                                data-sal="slide-up"
                                data-sal-duration="800">
                            Filter <i class="feather-filter"/>
                        </button>
                    </div>
                </div>
            </div>

            <div class="collapse default-exp-wrapper" id="collapseExample">
                <div class="inner">
                    <div class="filter-select-option">
                        <label class="filter-leble">Nom</label>
                        <input type="text" v-model="key_name" @keyup="filterFreelanceByFields" style="border: .5px solid slategray; border-radius: 8px; padding: .5rem 1rem;" placeholder="Ex: Jean pière" >
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Titre</label>
                        <input type="text" v-model="key_title" @keyup="filterFreelanceByFields" style="border: .5px solid slategray; border-radius: 8px; padding: .5rem 1rem;" placeholder="Ex: Ingénieur" >
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Type projet</label>
                        <input type="text" v-model="key_project_type" @keyup="filterFreelanceByFields" style="border: .5px solid slategray; border-radius: 8px; padding: .5rem 1rem;" placeholder="Ex: UX Designe" >
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">TJM</label>
                        <input type="text" v-model="key_tjm" @keyup="filterFreelanceByFields" style="border: .5px solid slategray; border-radius: 8px; padding: .5rem 1rem;" placeholder="Ex: 250" >
                    </div>

                    <div class="filter-select-option">
                        <label class="filter-leble">Technologie</label>
                        <div v-if="lisTtechnoSelected.length" class="content-selected d-flex">
                             <p  v-for="techno in lisTtechnoSelected" :key="techno" >
                                <span>{{ techno }}</span>
                                <i class="feather-x" @click="filterAfterChange(techno)"></i>
                            </p>
                        </div>
                        <nice-select
                            :options="dataTechno"
                            :default-current="0"
                            placeholder="Sale type"
                            name="sale_type"
                            @onChange="addItemToSelectedTechno"
                            />
                    </div>
                        
                        <!-- <div class="filter-select-option">
                            <label class="filter-leble">Price Range</label>
                            <div class="price_filter s-filter clear">
                                <form action="#" method="GET">
                                    <range-slider
                                    @filterPrice="filterPrice"
                                    @onChangeRange="onChangeRange" 
                                    />
                            </form>
                        </div> -->

                </div>
            </div>

            <div class="row g-5"
                     data-sal="slide-up"
                     data-sal-delay="150"
                     data-sal-duration="800"
                        style="z-index: -1;">
                    <template v-for="(freelancer, index) in freelancersList" :key="index">
                        <div v-if="index < 10" class="col-5 col-lg-4 col-md-6 col-sm-6 col-12">
                            <freelancer-card
                                :freelancer = "freelancer"
                                :show-place-bid="showPlaceBid"
                                :product-style-class="showPlaceBid ? 'no-overlay with-placeBid' : ''"
                            />
                        </div>
                    </template>
                    <h3 v-if="!freelancersList.length" class="text-center">No Match Found</h3>
                </div>
            </div>
            
        </div>

</template>

<script>
import NiceSelect from '@/components/select/NiceSelect'
import RangeSlider from '@/components/range/RangeSlider'
import ProductFilterMixin from '@/mixins/ProductFilterMixin'
import FreelancerCard from '../onexus/FreelancerCard'

import { airtableApi } from '@/services/api_config'

export default {
    name: 'ExploreFilter',
    components: {RangeSlider, NiceSelect, FreelancerCard},
    mixins: [ProductFilterMixin],
    data(){
        return {
            key_name: '',
            key_title: '',
            key_project_type: '',
            key_tjm: '',
            key_techno: '',
            key_filter_techno: '',
            lisTtechnoSelected: [],
            freelancersList: [],
            freelancersListReserve: [],
            dataTechno: [{value: 'Select item' , text: 'Select item'}],
            freelancersTechno: [],
            freelancersTechnoReserve: [],
            onProcessing: false
        }
    },
    methods: {
        async getFreelancers() {
            this.onProcessing = true
            try{
                const response = await airtableApi.get(`/v0/appRVLOFnXG9wPeFh/tbl9nXBypW3Ox7SlD`)
                this.freelancersList = response.data.records
                this.freelancersListReserve = this.freelancersList
                this.getListTechno()
                this.createDataTechnoContent()
            }catch(error){
                console.log(error)
            }finally{
                this.onProcessing = false
            }
        },

        getListTechno(){
            this.freelancersListReserve.forEach(freelancer => {
                if(freelancer.fields['Technos']){
                    freelancer.fields['Technos'].forEach(techno => {
                        if(!this.freelancersTechno.includes(techno)){
                            this.freelancersTechno.push(techno)
                        }
                    })
                }
            })
            this.freelancersTechno = this.freelancersTechno.slice().sort()
            this.freelancersTechnoReserve = this.freelancersTechno
        },

        createDataTechnoContent(){
            this.freelancersTechnoReserve.forEach(techno => {
                this.dataTechno.push({
                    value: techno,
                    text: techno
                })
            })
        },

        filterListTechnologies(key){
            this.freelancersTechno = this.freelancersTechnoReserve.filter(techno => techno.toLowerCase().match(key.toLowerCase().trim()))
            this.freelancersTechno = this.freelancersTechno.slice().sort()
        },

        fillListTechno(){
            this.freelancersTechno = this.freelancersTechnoReserve.slice().sort()
        },

        filterFreelanceByFields() {
            this.freelancersList = this.freelancersListReserve.filter(freelancer => {
                return freelancer && (
                    freelancer.fields['Nom'] &&
                    freelancer.fields['titre'] &&
                    freelancer.fields['Type projet'] &&
                    freelancer.fields['TJM'] &&
                    freelancer.fields['Nom'].toLowerCase().match(this.key_name ? this.key_name.toLowerCase().trim() : '') &&
                    freelancer.fields['titre'].toLowerCase().match(this.key_title ? this.key_title.toLowerCase().trim() : '') &&
                    freelancer.fields['Type projet'].toLowerCase().match(this.key_project_type ? this.key_project_type.toLowerCase().trim() : '') &&
                    freelancer.fields['TJM'].toLowerCase().match(this.key_tjm ? this.key_tjm.toLowerCase().trim() : '') &&
                    this.lisTtechnoSelected.every(techno => freelancer.fields['Technos'].map(techno_freelancer => techno_freelancer.toLowerCase()).includes(techno.toLowerCase()))
                )
            })
            
            if(this.freelancersList.length>0){
                this.isResultExist = true
            }else{
                this.isResultExist = false
            }
        },

        addItemToSelectedTechno(item){
            if(!this.lisTtechnoSelected.includes(item.value)){
                this.lisTtechnoSelected.push(item.value)
                this.filterFreelanceByFields()
            }
        },

        filterAfterChange(techno){
            this.lisTtechnoSelected.splice(this.lisTtechnoSelected.indexOf(techno),1)
            this.filterFreelanceByFields()
        }
    },
    mounted(){
        this.getFreelancers()
    }
}
</script>

<style lang="scss">

.content-selected{
    height: 4rem;
    border: .5px solid slategray;
    border-radius: 8px;
    margin-bottom: 5px;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    overflow: auto;
    p{
        display: flex;
        justify-content: center;
        align-items: center;
        border: .5px solid slategrey;
        border-radius: 5px;
        padding: .2rem .5rem .2rem 1rem;
        margin: 5px;
        line-height: 1;
        i{
            margin-left: 1rem;
            cursor: pointer;
            transition: .5s ease;
            line-height: 1;
        }
        i:hover{
            transform: rotate(90deg);
            border: .2px solid slategray;color: rgb(236, 108, 108);
            border-radius: 50%;
        }
    }
}

</style>