<template>
    <layout>
        <!-- <breadcrumb title="Profile" current="Profile"/> -->

        <div class="mt-5 mb-5 ">
            <div class="container-fluid">
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-12 d-flex justify-content-between mb--30 align-items-center">
                        <h4 class="title-left">{{ this.$t('user_profile_page.display_text.edit_user_profile') }}</h4>
                    </div>
                </div>
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <nav class="left-nav rbt-sticky-top-adjust-five">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <button class="nav-link active"
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true">
                                    <i class="feather-camera"/> {{ this.$t('user_profile_page.options.image_option') }}
                                </button>
                                <button class="nav-link"
                                        id="nav-home-tabs"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-homes"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-homes"
                                        aria-selected="false">
                                    <i class="feather-user"/> {{ this.$t('user_profile_page.options.personal_information_option') }}
                                </button>
                                <button class="nav-link"
                                        id="nav-profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected="false">
                                    <i class="feather-unlock"/> {{ this.$t('user_profile_page.options.password_option') }}
                                </button>
                                <button class="btn btn-primary" @click="updateUserProfile">
                                    <i class="feather-save"/>
                                    <span>{{ this.$t('user_profile_page.options.save_option') }}</span>
                                </button>
                            </div>
                        </nav>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt_sm--30">
                        <div class="tab-content tab-content-edit-wrapepr" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div class="nuron-information">
                                    <div class="profile-change row ">
                                        <div class="profile-left right col-12">
                                            <div class="profile-image d-flex justify-content-center align-items-center">
                                                <div class="d-flex justify-content-center align-items-center  user-image">
                                                    <img class="user-image-view" v-if="url_image!==null" :src="url_image" :alt="user.type==='freelance'? user.first_name : user.company_name">
                                                    <i v-else class="feather-image"></i>
                                                    <input type="file" hidden ref="newImage" @change="handleImageSelected($event)" >
                                                    <button @click="$refs.newImage.click()" title="Add image">
                                                        <i class="feather-camera"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-homes" role="tabpanel" aria-labelledby="nav-home-tab" v-if="user.type==='freelance'">
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="input-two-wrapper mb--15">
                                            <div class="first-name half-wid">
                                                <label class="form-label">{{ this.$t('user_profile_page.fields.first_name') }}</label>
                                                <input v-model="user.first_name" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text">
                                            </div>
                                            <div class="last-name half-wid">
                                                <label  class="form-label">{{ this.$t('user_profile_page.fields.last_name') }}</label>
                                                <input v-model="user.last_name" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')"  type="text">
                                            </div>
                                        </div>
                                        <div class="email-area">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.email') }}</label>
                                            <input v-model="user.email" readonly :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="email">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.city') }}</label>
                                            <input v-model="user.country" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.address') }}</label>
                                            <input v-model="user.address" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                    </div>
                                    
                                    <div class="input-two-wrapper mt--15">
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.contact') }}</label>
                                            <input v-model="user.contact" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                    </div>
                                    <div class="profile-form-wrapper mt--15">
                                        <div class="email-area mt--15 select-file-pdf">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.portfolio') }}</label>
                                            <input v-model="user.portfolio" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')"  type="text">
                                        </div>
                                        <div class="email-area mt--15 select-file-pdf">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.cv') }}</label>
                                            <input :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="file_select.cv_name"  type="text"   >
                                            <input type="file" @change="handleFilePdf($event, 'cv', 'cv_name')" hidden ref="newCv" >
                                            <i class="feather-file-text" @click="$refs.newCv.click()" ></i>
                                        </div>
                                        <div class="email-area mt--15 select-file-pdf">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.cin') }}</label>
                                            <input :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="file_select.cin_name"  type="text"   >
                                            <input type="file" @change="handleFilePdf($event, 'cin', 'cin_name')" hidden ref="newCin" >
                                            <i class="feather-file-text" @click="$refs.newCin.click()" ></i>
                                        </div>
                                        <div class="email-area mt--15 select-file-pdf">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.nifstat') }}</label>
                                            <input :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="file_select.nifstat_name"  type="text"   >
                                            <input type="file" @change="handleFilePdf($event, 'nifstat', 'nifstat_name')" hidden ref="newNifstat" >
                                            <i class="feather-file-text" @click="$refs.newNifstat.click()" ></i>
                                        </div>
                                    </div>
                                    <div class=" mt--30" >
                                        <div class="d-flex justify-content-between align-items-center p-3">
                                            <label>{{ this.$t('user_profile_page.fields.technologies') }}</label>
                                        </div>
                                        <div class="content-techno-description p-3">
                                            <div v-if="!user.technologies.length" class="replace-when-empty d-flex justify-content-center align-items-center p-4 gap-2">
                                                <i class="feather-briefcase"></i>
                                                <p>{{ this.$t('user_profile_page.display_text.empty_item') }}</p>
                                            </div>
                                            <div v-else class="technos-descriptions d-flex justify-content-start align-items-center gap-4 ps-3">
                                                <div class="techno d-flex justify-content-center align-items-center gap-2 " 
                                                v-for="techno in user.technologies" :key="techno">
                                                    <span>{{ techno }}</span>
                                                    <i class="feather-x" @click="user.technologies.splice(user.technologies.indexOf(techno),1)" ></i>
                                                </div>
                                            </div>
                                            <div class="add-technologies-descriptions d-flex justify-content-center align-items-center p-2 gap-2 mt-3">
                                                <input v-if="!add_techno" type="text" @keyup.enter="addTechnologie" @blur="add_techno=!add_techno" v-model="new_tech" class="w-50" >
                                                <button v-else class="btn btn-primary-alta"  @click="add_techno=!add_techno" ><i class="feather-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-homes" role="tabpanel" aria-labelledby="nav-home-tab" v-else>
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="first-name">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.company_name') }}</label>
                                            <input v-model="user.company_name" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                        <div class="email-area mt--15">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.email') }}</label>
                                            <input v-model="user.email" readonly :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="email">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.country') }}</label>
                                            <input v-model="user.state" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.city') }}</label>
                                            <input v-model="user.country" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                    </div>
                                    
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.address') }}</label>
                                            <input v-model="user.address" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.contact') }}</label>
                                            <input v-model="user.contact" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                    </div>
                                    
                                    <div class="profile-form-wrapper">
                                        <div class="role-area mt--15">
                                            <label class="form-label mb--10">{{ this.$t('user_profile_page.fields.rcs') }}</label>
                                            <input v-model="user.rcs" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" type="text" >
                                        </div>
                                    </div>
                                    
                                    <div class="edit-bio-area mt--30">
                                        <label for="description" class="form-label">{{ this.$t('user_profile_page.fields.description') }}</label>
                                        <textarea v-model="user.description" id="description" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')"></textarea>
                                    </div>

                                    <div class="profile-form-wrapper mt--15">
                                        <div class="email-area mt--15 select-file-pdf">
                                            <label class="form-label">{{ this.$t('user_profile_page.fields.portfolio') }}</label>
                                            <input v-model="user.portfolio" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')"  type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="nuron-information">
                                    <div class="condition">
                                        <h5 class="title">{{ this.$t('user_profile_page.fields.password') }}</h5>
                                        <p class="condition">{{ this.$t('user_profile_page.display_text.security_info') }}</p>
                                        <hr />
                                        <div class="email-area">
                                            <label for="Email2" class="form-label">{{ this.$t('user_profile_page.fields.email_required') }}</label>
                                            <input id="Email2" type="email" v-model="user.email" >
                                        </div>
                                    </div>
                                    <div class="input-two-wrapper mt--15">
                                        <div class="old-password half-wid">
                                            <label for="oldPass" class="form-label">{{ this.$t('user_profile_page.fields.old_password') }}</label>
                                            <input id="oldPass" type="password" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="old_password" >
                                        </div>
                                        <div class="new-password half-wid">
                                            <label for="NewPass" class="form-label">{{ this.$t('user_profile_page.fields.new_password') }}</label>
                                            <input id="NewPass" type="password" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="new_password" >
                                        </div>
                                    </div>
                                    <div class="email-area mt--15">
                                        <label for="rePass" class="form-label">{{ this.$t('user_profile_page.fields.confirm_new_password') }}</label>
                                        <input id="rePass" type="password" :placeholder="this.$t('user_profile_page.display_text.placeholder_text')" v-model="confirm_password" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <Teleport to="#on-loading-process">
            <OnLoading v-if="on_processing"/>
        </Teleport>
    </layout>
</template>

<script>
import Layout from "@/components/layouts/Layout"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb"
import OnLoading from "@/components/popups/OnLoading"
import store from '../store'
import { updateUser } from "../services/users_services"

import BACKEND_URL_BASE_API from "@/services/api_url_base"

export default {
    name: 'UserProfileEdit',
    components: {Breadcrumb, Layout, OnLoading},
    data(){
        return {
            backend_url: BACKEND_URL_BASE_API,
            image_file: false,
            on_processing: false,
            add_techno: true,
            new_tech: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            file_select: {
                cv_name: null,
                cin_name: null,
                nifstat_name: null,
            },
            url_image: null,
            user: {
                email: '',
                type: '',
                image: null,
                portfolio: '',
                sanction: false,
                password: '',
                city: '',
                first_name: '',
                last_name: '',
                address: '',
                contact: '',
                cv: null,
                cin: null,
                nifstat: null,
                technologies: [],
                company_name: '',
                rcs: '',
                state: '',
                country: '',
                activity: '',
                description: '',
            }
        }
    },
    mounted() {
        const user_selected = store.state.user
        if(user_selected){
            this.user.email = user_selected.email
            this.user.type = user_selected.type
            this.user.image = user_selected.image
            this.user.portfolio = user_selected.portfolio
            this.user.password = user_selected.password
            this.url_image  = user_selected.image ? (this.backend_url + '/' + user_selected.image) : null
            console.log(this.url_image)
            if(user_selected.type === 'freelance'){
                this.user.first_name = user_selected.freelance.first_name
                this.user.last_name = user_selected.freelance.last_name
                this.user.city = user_selected.freelance.city
                this.user.address = user_selected.freelance.address
                this.user.contact = user_selected.freelance.contact
                this.user.cv = user_selected.freelance.cv
                this.user.cin = user_selected.freelance.cin
                this.user.nifstat = user_selected.freelance.nifstat
                try {
                    this.user.technologies = user_selected.freelance.technologies? user_selected.freelance.technologies : []
                } catch (error) {
                    console.error("Failed to parse technologies:", error)
                }
            }
            else{
                this.user.company_name = user_selected.client.company_name
                this.user.rcs = user_selected.client.rcs
                this.user.city = user_selected.client.city
                this.user.state = user_selected.client.state
                this.user.country = user_selected.client.country
                this.user.activity = user_selected.client.activity
                this.user.description = user_selected.client.description
            }
        }
        console.log(this.user)
    },
    methods: {
        handleImageSelected(event){
            this.user.image = event.target.files[0]
            if(this.user.image){
                const fileType = this.user.image.type
                if(fileType.startsWith('image/')){
                    this.url_image = URL.createObjectURL(this.user.image)
                    this.image_file = true
                }else{
                    alert('Veuillez séléctioner une image')
                    this.image_file = false
                    this.url_image = null
                }
            }
        },
        addTechnologie(){
            if(this.new_tech && !this.user.technologies.map(techno => techno.toLowerCase()).includes(this.new_tech.toLowerCase().trim())){
                this.user.technologies.push(this.new_tech.trim())
            }
            this.new_tech = ''
            this.add_techno = false
        },
        handleFilePdf(event, user_file, user_file_name){
            const file = event.target.files[0]
            if(file){
                const fileType = file.type
                if(fileType==='application/pdf'){
                    this.user[user_file] = file
                    this.file_select[user_file_name] = file.name
                }else{
                    alert('Veuillez sélctioner un fichier PDF')
                    this.user[user_file] = null
                    this.file_select[user_file_name] = null
                }
            }
        },
        
        async updateUserProfile(){
            console.log(this.user)
            this.on_processing = true
            let table_techno = this.user.technologies
            this.user.technologies = JSON.stringify(table_techno)
            try {
                const formData = new FormData()
                for (const key in this.user) {
                    if (this.user[key] !== undefined && this.user[key] !== null) {
                        formData.append(key, this.user[key])
                    }
                }
                const response = await updateUser(formData)
                console.log(response)
            } catch (error) {
                console.log(error)
            } finally {
                this.on_processing = false
                this.user.technologies = table_techno
            }
        }
    },
}
</script>

<style lang="scss" >
.user-image{
    position: relative;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    background: #56575848;
    flex-wrap: wrap;
    img.user-image-view{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    i.feather-image{
        position: absolute;
        font-size: 17rem;
        color: #56575848;
    }
    button{
        width: 4rem;height: 4rem;
        position: absolute;
        bottom: 0;right: 0;
        cursor: pointer;
        border-radius: 4px;
        i{
            line-height: 1;
        }
    }
    button:hover{
        color: #fafafa;
        background: #195396;
        border: none;
    }
}
.replace-when-empty{
    flex-direction: column;
    i{
        font-size: 3rem;
    }
    p{
        font-size: 2rem;
        font-style: italic;
    }
}
.technos-descriptions{
    flex-wrap: wrap;
    padding: 1rem;
    .techno{
        border: 1px solid slategray;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        span{
            font-size: 1.6rem;
        }
        i{
            font-size: 1.4rem;
            cursor: pointer;
            line-height: 1;
            transition: .5s ease;
        }
        i:hover{
            border: .1px solid slategray;
            border-radius: 50%;
            color: #f19393d3;
            transform: rotate(90deg);
        }
    }
}
.select-file-pdf{
    position: relative;
    i{
        position: absolute;
        right: 1rem;top: 50%;
        cursor: pointer;
        transition: .5s ease;
    }
    i:hover{
        color: #195396;
        font-size: 2.5rem;
    }
}
</style>