<template>
    <div class="container-fluid bg_image--15 bg_image"  data-black-overlay="8">
        <div class="about-fluidimg-cta">
            <div class="rn-banner-area">
                <div class="slider-style-7 ">
                    <div class="rn-banner-wrapper row">
                        <div class="col-xl-5 col-lg-12 col-12 order-3 order-xl-1">
                            <div class="item-description">
                                <p class="px-md-5 m-0">
                                    {{ this.$t('home_page_onexus.display_text.gems_ready') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-12 order-2 order-xl-2">
                            <h2 class="title">
                                {{ this.$t('home_page_onexus.display_text.find_ideal_freelancer_break_1') }}<br>
                                <div v-if="this.$i18n.locale==='fr'">
                                    <span>{{ this.$t('home_page_onexus.display_text.find_ideal_freelancer_break_2') }}</span>
                                    {{ this.$t('home_page_onexus.display_text.find_ideal_freelancer_break_3') }}
                                </div>
                                <div v-else>
                                    {{ this.$t('home_page_onexus.display_text.find_ideal_freelancer_break_3') }}
                                    <span>{{ this.$t('home_page_onexus.display_text.find_ideal_freelancer_break_2') }}</span>
                                </div>
                            </h2>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-12 order-1 order-xl-3">
                            <!-- <router-link class="btn btn-large btn-primary" to="/findFreelancer" target="_blank" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800"> -->
                            <a href="https://calendar.app.google/cR1CSqq4w3zD32Us6" target="_blank" class="btn btn-large btn-primary" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">
                               {{ this.$t('actions.book_slot') }}
                            </a>
                            <div class="img-thumb-award">
                                <!-- <img :src="require(`@/assets/images/logo/award-logo.png`)" alt=""> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
    export default {
        name: 'CallToAction2',
    }
</script>

<style scoped>
.bg_image--15 {
    background-image: url('https://rainbowit.net/html/nuron/assets/images/bg/bg-image-15.jpg');
    background-attachment : fixed
}
.rn-callto-action{
    padding: 0px;
}
</style>