
import { createStore } from 'vuex'
import { signupUser, loginUser, getUser, updateUser } from './services/users_services'
import { createProject, getClientProjects } from './services/project_sercive'

const store = createStore({
    state() {
        return {
            user: null,
            isConnected: false,
            clientProjects: [],
            updateProject: null,
        }
    },

    mutations: {
        setUser(state, user) {
            state.user = user
            state.isConnected = !!user
        },
        clearUser(state) {
            state.user = null
            state.isConnected = false
        },
        updateUser(state, user) {
            state.user = user
        },
        setUpdateProject(state, project) {
            state.updateProject = project
        },
        clearUpdateProject(state) {
            state.updateProject = null
        },
        setClientListProject(state, listProject) {
            state.clientProjects = listProject
        },
        pushNewProject(state, project){
            state.clientProjects.push(project)
        }
    },

    actions: {

        async loginInfo({ commit }, user) {
            try {
                const response = await loginUser(user)
                if (response.status === 200 && response.data.user) {
                    commit('setUser', response.data.user)
                } else {
                    commit('clearUser')
                }
                return response
            } catch (error) {
                console.log(error)
                commit('clearUser')
                return error.response
            }
        },

        logoutInfo({ commit }) {
            commit('clearUser')
        },

        async updateUserInfo({ commit }, user) {
            try {
                const response = await updateUser(user)
                if (response.status === 200 && response.data.user) {
                    commit('updateUser', response.data.user)
                } else {
                    commit('clearUser')
                }
                return response
            } catch (error) {
                console.log(error)
                commit('clearUser')
                return error.response
            }
        },

        async subscribeUser({ commit }, user) {
            try {
                const response = await signupUser(user)
                if (response.status === 201 && response.data.user) {
                    commit('setUser', response.data.user)
                } else {
                    commit('clearUser')
                }
                return response
            } catch (error) {
                console.log(error)
                commit('clearUser')
                return error.response
            }
        },

        async checkAuthentication({ commit }) {
            try {
                const response = await getUser()
                const userAuthenticated = response.data?.user ? response.data?.user : null
                if (userAuthenticated) {
                    commit('setUser', userAuthenticated)

                    this.getClientListProject
                } else {
                    commit('clearUser')
                }
            } catch (error) {
                commit('clearUser')
                console.log(error)
                throw error
            }
        },

        async createNewProject({ commit }, project) {
            try{
                const response = await createProject(project)
                if((response.status === 200 || response.status === 201) && response.data.project){
                    commit('pushNewProject', response.data.project)
                }
            }catch(error){
                console.log(error)
                throw error
            }
        },

        async getClientListProject([commit]){
            try{
                const response = getClientProjects()
                if(response.status === 200 && response.data.projects){
                    commit('setClientListProject', response.data.projects)
                }
            }catch(error){
                console.log(error)
                throw error
            }
        }
    }
})

export default store