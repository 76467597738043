<template>
    <layout>
        <!-- <breadcrumb title="Nuron Login" current="Nuron Login"/> -->

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5 d-flex justify-content-center ">
                    <div class="col-6 col-md-6 ml_md--0 ml_sm--0">
                        <div class="form-wrapper-one">
                            <h4>{{ this.$t('login_signup_page.auth.login') }}</h4>
                            <form @submit.prevent="login">
                                <div class="mb-5">
                                    <label class="form-label">{{ this.$t('login_signup_page.fields.email') }}</label>
                                    <input :class="{'check-valide': error_email}" type="email" v-model="user.email" >
                                    <span v-if="!!error_email" class="error-message">{{ error_messages.email }}</span>
                                </div>
                                <div class="mb-5">
                                    <label class="form-label">{{ this.$t('login_signup_page.fields.password') }}</label>
                                    <input  :class="{'check-valide': error_password}" type="password" v-model="user.password" >
                                    <span v-if="!!error_password" class="error-message">{{ error_messages.password }}</span>
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input type="checkbox" class="rn-check-box-input" id="exampleCheck1">
                                    <label class="rn-check-box-label" for="exampleCheck1">{{ this.$t('login_signup_page.fields.remember_me') }}</label>
                                </div>
                                <button type="submit" class="btn btn-primary mr--15">{{ this.$t('login_signup_page.auth.login') }}</button>
                                <router-link to="/sign-up" class="btn btn-primary-alta">{{ this.$t('login_signup_page.auth.signup') }}</router-link>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Another way to log in</h6>
                            <p>Lorem ipsum dolor sit, amet sectetur adipisicing elit.cumque.</p>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <Teleport to="#on-loading-process">
            <OnLoading v-if="onProcessing"/>
        </Teleport>

    </layout>
    
</template>

<script>
import Layout from "@/components/layouts/Layout"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb"
import OnLoading from "@/components/popups/OnLoading.vue"
import { mapActions } from "vuex"

import FieldsMethodValidator from "../mixins/FieldsMethodValidator"


export default {
    name: 'Login',
    components: {Breadcrumb, Layout, OnLoading},
    mixins: [ FieldsMethodValidator ],
    data(){
        return {
            onProcessing: false,
            error_messages: {
                email: '',
                password: ''
            },
            error_email: false,
            error_password: false,
            user: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        ...mapActions(['loginInfo']),
        async login(){
            if(!this.checkInformation()){
                this.onProcessing = true
                try{
                    const response = await this.loginInfo(this.user)
                    if(response.status === 200){
                        this.$router.push('/user-profile')
                    }else{
                        alert(response.data.message)
                    }
                }catch(error){
                    alert('Une erreur est survenu, veuillez réessayer')
                }finally{
                    this.onProcessing = false
                }
            }
        },
        checkInformation(){
            this.error_email = !this.valideFieldsRegex('user', 'email', /^[^\s@]+@[^\s@]+\.[^\s@]+$/ )
            this.error_password = !this.valideFieldsLength('user', 'password')
            return (this.error_email || this.error_password)
        },
    }
}
</script>