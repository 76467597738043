import axios from 'axios'

export const backendApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
    //timeout: process.env.VUE_APP_TIMEOUT,
})

export const airtableApi = axios.create({
    baseURL: process.env.VUE_APP_API_AIRTABLE,
    headers: {
        'Authorization' : 'Bearer patUgCaODP2MMQWfa.003a848cede4738796e0a0443f6aeea7bd6beb18a841fb7b2447cf222e7d4073'
    }
})

//axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
//axios.defaults.timeout = process.env.VUE_APP_TIMEOUT
//axios.defaults.withCredentials = true
//export default axios