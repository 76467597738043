<template>
    <ul class="mainmenu">
        <li v-for="(item, index) in navItems" :key="`nav-item-${index}`">
            <router-link :to="item.link" class="nav-link its_new">
                <p><small>{{ item.title }}</small></p>
            </router-link>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'Nav',
    data() {
        return {
            navItems: []
        }
    },
    mounted(){
        this.addLinkIntem()
    },
    methods: {
        addLinkIntem(){
            this.navItems = [
                {
                    link: '/',
                    title: this.$t('title_page_links.home_page'),
                },
                {
                    link: '#',
                    title: this.$t('title_page_links.dashboard_page'),
                },
                {
                    link: 'client-projects',
                    title: this.$t('title_page_links.project_page'),
                },
                {
                    link: 'user-profile',
                    title: this.$t('title_page_links.profil_page'),
                },
            ]
        }
    },
    watch: {
        '$i18n.locale'(locale){
            this.addLinkIntem()
        }
    }
}
</script>