<template>
  <div class="loading-container">
    <div class="loading-process"></div>
    <p> Just a sec... </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.loading-container{
    position: fixed;
    top: 0; left: 0; right: 0;bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    z-index: 1000;
}
.loading-process{
  border: 14px solid #f3f3f3;
  border-top: 14px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin .8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>