<template>
    <div :class="`product-style-one ${productStyleClass}`">
        <!-- <div class="card-thumbnail position-relative" v-if="freelancer?.fields?.Photo[0]"> -->
        <div class="card-thumbnail position-relative">
            <img  :src="freelancer?.fields?.Photo[0].thumbnails.large.url" :alt="freelancer?.fields?.Photo[0].url" @load="$emit('handleImageLoad')">
            <!-- TJM -->
            <div class="tjm_card blur_background">
                {{freelancer.fields["TJM"]}} €/J
            </div>
        </div>
        <!-- <div class="card-thumbnail position-relative" v-else>
            <span>Picture not found</span>
        </div> -->
        <div class="product-share-wrapper">
        </div>
        <span class="product-name">{{freelancer.fields["Nom"]}}</span>
        <div class="d-flex justify-content-between">
            <span class="more-author-text">{{ freelancer.fields["Année d'expérience"] }} ans xp</span>
            <!-- CONTACT -->
            <router-link to="/find-freelancer" target="_blank">
                <div class="bid-react-area m-0">
                    <div class="react-area">
                        <i class="feather-send m-0"/>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="bid-react-area">
            <div class="last-bid">{{freelancer.fields["titre"]}}</div>
        </div>
        <div class="d-flex my-4">
            <span v-for="(techno, index) in freelancer.fields.Technos" :key="index" class="badge-list">
                {{techno}}
            </span>
        </div>
        <span class="latest-bid">{{freelancer.fields["Type projet"]}}</span>
    </div>
</template>

<script>
    export default {
        name: 'FreelancerCard',
        props: {
            freelancer: {},
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>
.badge-list{
    padding: 0.5rem 1rem;
    margin: 0.2rem;
    /* background-color: red !important; */
    border-radius: 20px;
    border: 1px solid;
    border-color: #727272;
}

.tjm_card{
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.react-area {
    /* background-color: tomato !important; */
    border: 1px solid white
}

.blur_background {
  padding: 10px 15px;
  background: var(--background-color-1);
  border-radius: 6px;
  max-width: 200px;
  margin: 7px;
  border: 1px solid var(--color-border);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  color: white;
  font-weight: bold;
}
.product-name{
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    transition: 0.4s;
    color: invert(var(--color-heading));
}
</style>