<template>
    <layout>
        <breadcrumb title="Détails du projet" current="Détails du projet"/>

        <div class="product-details-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="col-12 rn-pd-content-area mt-0">
                        <router-link class="btn btn-primary-alta btn-small" to="/client-projects">
                            <i class="feather-arrow-left"></i>
                            <span>Retour</span>
                        </router-link>
                    </div>
                    <div class=" col-12 ">
                        <div class="rn-pd-content-area">
                            <div class="pd-title-area">
                                <h4 class="title">{{ projectSelected?.project_ame }}</h4>
                                <div class="pd-react-area">
                                    <div class="heart-count">
                                        <i class="feather-heart"/>
                                        <span>254</span>
                                    </div>
                                    <div class="count">
                                        <div class="share-btn share-btn-activation dropdown">
                                            <button class="icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="feather-more-horizontal"/>
                                            </button>
                                            <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                                                <button type="button" class="btn-setting-text" data-bs-toggle="modal" data-bs-target="#shareModal">
                                                    Modifier le projet
                                                </button>
                                                <button type="button" class="btn-setting-text" data-bs-toggle="modal" data-bs-target="#reportModal">
                                                    Faire un rapport
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="bid">
                                Budget <span class="price">${{ projectSelected?.budget }}</span>
                            </span>
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 class="title-name">Liste des freelances</h6>
                                <router-link class="btn btn-primary-alta btn-small" to="/freelancer-filter">Trouver les freelances</router-link>
                            </div>
                            <div class="rn-bid-details">
                                <div class="tab-wrapper-one">
                                    <nav class="tab-button-one">
                                        <div class="nav nav-tabs d-flex justify-content-between align-items-center" id="nav-tab" role="tablist">
                                            <button class="nav-link active"
                                                    id="nav-all-freelances-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-all-freelances"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-all-freelances"
                                                    aria-selected="false">
                                                    Freelances proposés
                                            </button>
                                            <button class="nav-link"
                                                    id="nav-freelances-added-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-freelances-added"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-freelances-added"
                                                    aria-selected="true">
                                                    Freelances ajoutés
                                            </button>
                                            <button class="nav-link"
                                                    id="nav-freelances-skipped-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-freelances-skipped"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-freelances-skipped"
                                                    aria-selected="false">
                                                    Freelance ignorés
                                            </button>
                                        </div>
                                    </nav>
                                    <div class="tab-content rn-bid-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-all-freelances" role="tabpanel" aria-labelledby="nav-all-freelances-tab">
                                            <div class="top-seller-inner-one">
                                                <div class="top-seller-wrapper">
                                                    <div class="thumbnail">
                                                        <router-link to="#"><img :src="require(`@/assets/images/client/client-3.png`)" alt="Nft_freelances-added"></router-link>
                                                    </div>
                                                    <div class="top-seller-content">
                                                        <span>0.11wETH by <router-link to="#">Allen Waltker</router-link></span>
                                                        <span class="count-number">1 hours ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-freelances-added" role="tabpanel" aria-labelledby="nav-freelances-added-tab">
                                            <div class="top-seller-inner-one">
                                                <div class="top-seller-wrapper">
                                                    <div class="thumbnail">
                                                        <router-link to="#"><img :src="require(`@/assets/images/client/client-3.png`)" alt="Nft_freelances-added"></router-link>
                                                    </div>
                                                    <div class="top-seller-content">
                                                        <span>0.11wETH by <router-link to="#">Allen Waltker</router-link></span>
                                                        <span class="count-number">1 hours ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-freelances-skipped" role="tabpanel" aria-labelledby="nav-freelances-skipped-tab">
                                            <div class="top-seller-inner-one">
                                                <div class="top-seller-wrapper">
                                                    <div class="thumbnail">
                                                        <router-link to="#"><img :src="require(`@/assets/images/client/client-3.png`)" alt="Nft_freelances-added"></router-link>
                                                    </div>
                                                    <div class="top-seller-content">
                                                        <span>0.11wETH by <router-link to="#">Allen Waltker</router-link></span>
                                                        <span class="count-number">1 hours ago</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="place-bet-area">
                                    <div class="rn-bet-create">
                                        <div class="bid-list winning-bid">
                                            <h6 class="title">Projet</h6>
                                            <div class="top-seller-inner-one">
                                                <div class="top-seller-wrapper">
                                                    <div class="thumbnail">
                                                        <router-link to="#">
                                                            <img :src="require(`@/assets/images/client/client-7.png`)" alt="Nft_freelances-added">
                                                        </router-link>
                                                    </div>
                                                    <div class="top-seller-content">
                                                        <span class="heighest-bid">{{ projectSelected?.project_ame }}</span>
                                                        <span class="count-number">Nombre freelance ajouté: {{ projectSelected?.nb_freelances_demande }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bid-list left-bid">
                                            <h6 class="title">Duré du projet</h6>
                                            <countdown :date="projectSelected?.end_date" class="mt--15"/>
                                        </div>
                                    </div>
                                    <button type="button" 
                                            class="btn btn-primary-alta mt--30" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#placebidModal">
                                        Payment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <share-modal/>
        
        <report-modal/>

        <placebid-modal/>
    </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
    import ProductCard from '@/components/product/ProductCard'
    import ShareModal from '@/components/modal/ShareModal'
    import ReportModal from '@/components/modal/ReportModal'
    import Countdown from '@/components/product/Countdown'
    import PlacebidModal from '@/components/modal/PlacebidModal'
    import ProductMixin from "@/mixins/ProductMixin"
    import store from '../store'

    export default {
        name: 'ProductDetails',
        components: {PlacebidModal, Countdown, ReportModal, ShareModal, ProductCard, Breadcrumb, Layout},
        mixins: [ProductMixin],
        data() {
            return {
                listFreelances: [],
                listFreelancesActive: [],
                listFreelancesSkiped: [],
                projectSelected: null
            }
        },
        methods: {
            getProduct(productId) {
                this.product = this.products.find(item => item.id == productId);
            }
        },
        mounted(){
            this.projectSelected = store.state.projectSelected
            console.log(this.projectSelected)
        }
    }
</script>