<template>
    <div class="rn-callto-action rn-section-gapTop">
            <div class="container-fluid bg_image--6 bg_image bg-image-border" data-sal-delay="150" data-black-overlay="7">
                <div class="about-fluidimg-cta">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="" >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="call-to-action-wrapper">
                                            <h3 data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                                {{ this.$t('home_page_onexus.display_text.we_are_here') }}
                                            </h3>
                                            <p data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                                {{ this.$t('home_page_onexus.display_text.contact_us') }}
                                            </p>
                                            <div class="callto-action-btn-wrapper"
                                            data-sal="slide-up"
                                            data-sal-duration="800"
                                                 data-sal-delay="150">
                                                <!-- <router-link to="/contact" class="btn btn-primary btn-large">
                                                    Contactez-nous
                                                </router-link> -->
                                                <a href="https://calendar.app.google/cR1CSqq4w3zD32Us6" target="_blank" class="btn btn-primary btn-large">
                                                    {{ this.$t('actions.book_slot') }}
                                                </a>
                                                <!-- <router-link to="/contact" class="btn btn-primary-alta btn-large">
                                                    Contact Us
                                                </router-link> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'CallToAction1',
    }
</script>

<style scoped>
.bg_image--6 {
    background-image: url('https://rainbowit.net/html/nuron/assets/images/bg/bg-image-22.jpg');
    background-attachment: fixed;
}
.rn-callto-action{
    padding: 0px;
}
</style>