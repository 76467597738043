<template>
    <layout>

        <div class="d-flex gap-5">
            <div>
                <!-- Language -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Language</label>
                    <i class="feather-globe"></i>
                </div> <br>
        
                <!-- Messages -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Messages</label>
                    <i class="feather-message-circle"></i>
                </div> <br>
        
                <!-- Notifications -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Notifications</label>
                    <i class="feather-bell"></i>
                </div> <br>
        
                <!-- User -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>User</label>
                    <i class="feather-user"></i>
                </div> <br>
        
                <!-- Users -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Users</label>
                    <i class="feather-users"></i>
                </div> <br>
        
                <!-- PDF file -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>PDF file</label>
                    <i class="feather-file-text"></i>
                </div> <br>
        
                <!-- Image file -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Image file</label>
                    <i class="feather-image"></i>
                </div> <br>
        
                <!-- Camera -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Camera</label>
                    <i class="feather-camera"></i>
                </div> <br>
        
                <!-- Home -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Home</label>
                    <i class="feather-home"></i>
                </div> <br>
        
                <!-- Project -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Project</label>
                    <i class="feather-briefcase"></i>
                </div> <br>
        
                <!-- Private message -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Project</label>
                    <i class="feather-lock"></i>
                </div> <br>
        
                <!-- Public message -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Public message</label>
                    <i class="feather-cast"></i>
                </div> <br>
        
                <!-- Public message -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Public message</label>
                    <i class="feather-rss"></i>
                </div> <br>
        
            </div>
            <div>
                <!-- Group message -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Group message</label>
                    <i class="feather-users"></i>
                </div> <br>

                <!-- Options -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Options</label>
                    <i class="feather-more-horizontal"></i>
                </div> <br>
        
                <!-- Parameters -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Parameters</label>
                    <i class="feather-settings"></i>
                </div> <br>
        
                <!-- Three buttons (menu) -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Three buttons (menu)</label>
                    <i class="feather-more-vertical"></i>
                </div><br>
        
                <!-- Room -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Room</label>
                    <i class="feather-layout"></i>
                </div> <br>
        
                <!-- Activities -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Activities</label>
                    <i class="feather-activity"></i>
                </div> <br>
        
                <!-- Office -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Office</label>
                    <i class="feather-briefcase"></i>
                </div> <br>
        
                <!-- Information -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Information</label>
                    <i class="feather-info"></i>
                </div> <br>
        
                <!-- Warning -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Warning</label>
                    <i class="feather-alert-triangle"></i>
                </div> <br>
        
                <!-- Question -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Question</label>
                    <i class="feather-help-circle"></i>
                </div> <br>
        
                <!-- Exclamation -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Exclamation</label>
                    <i class="feather-alert-circle"></i>
                </div> <br>
        
                <!-- Like -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Like</label>
                    <i class="feather-thumbs-up"></i>
                </div> <br>
        
                <!-- Heart -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Heart</label>
                    <i class="feather-heart"></i>
                </div> <br>
        
                <!-- Star -->
                <div class="d-flex justify-content-center align-item-center gap-3">
                    <label>Star</label>
                    <i class="feather-star"></i>
                </div> <br>

            </div>    
        </div>            
    </layout>

</template>
<script>
    import Layout from "@/components/layouts/Layout";

    export default {
        name: 'Feathers',
        components: {Layout},
    }
</script>

<style >
    i{
        font-size: 2rem;
    }
</style>
