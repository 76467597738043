<template>
    <layout>
        <!-- <breadcrumb title="Profile" current="Profile"/> -->

        <div class="mt-5 mb-5">
            <div class="container" >
                <div class="row mb-3 plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0 d-flex justify-content-center">
                    <div class="col-12 d-flex justify-content-between align-items-center titre-profile">
                        <h4>{{ this.$t('user_profile_page.display_text.user_profile') }}</h4>
                    </div>
                </div>
                
                <div class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0 d-flex justify-content-center">
                    <div class="col-12">
                        <div class="tab-content tab-content-edit-wrapepr" id="nav-tabContent">
                            <div class="tab-pane fade show active mb-3">
                                <div class="nuron-information">
                                    <div class="profile-change row ">
                                        <div class="profile-left right col-12">
                                            <div class="profile-image d-flex justify-content-center align-items-center">
                                                <div class="d-flex justify-content-center align-items-center  user-image">
                                                    <img class="user-image-view" v-if="url_image!==null" :src="url_image" :alt="user.type==='freelance'? user.first_name : user.company_name">
                                                    <i v-else class="feather-image"></i>
                                                    <input type="file" hidden ref="newImage" @change="handleImageSelected($event)" >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade show active " id="nav-homes"  v-if="user.type==='freelance'">
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="first-name">
                                            <label class="form-label ">
                                                <i class="feather-user" ></i>
                                                {{ this.$t('user_profile_page.fields.fullname') }}
                                            </label>
                                            <input disabled v-model="user.first_name" >
                                        </div>
                                        <div class="email-area mt--15">
                                            <label class="form-label ">
                                                <i class="feather-at-sign" ></i>
                                                {{ this.$t('user_profile_page.fields.email') }}
                                            </label>
                                            <input disabled v-model="user.email" type="email">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-home" ></i>
                                                {{ this.$t('user_profile_page.fields.city') }}
                                            </label>
                                            <input disabled v-model="user.country" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-map" ></i>
                                                {{ this.$t('user_profile_page.fields.address') }}
                                            </label>
                                            <input disabled v-model="user.address" >
                                        </div>
                                    </div>
                                    
                                    <div class="input-two-wrapepr-prifile mt--15">
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-phone" ></i>
                                                {{ this.$t('user_profile_page.fields.contact') }}
                                            </label>
                                            <input disabled v-model="user.contact" >
                                        </div>
                                    </div>
                                    <div class="profile-form-wrapper mt--15">
                                        <div class="email-area mt--15 ">
                                            <label class="form-label ">
                                                <i class="feather-link" ></i>
                                                {{ this.$t('user_profile_page.fields.portfolio') }}
                                            </label>
                                            <input disabled v-model="user.portfolio" >
                                        </div>
                                        <div class="email-area mt--15 ">
                                            <label class="form-label ">
                                                <i class="feather-file-text" ></i>
                                                {{ this.$t('user_profile_page.fields.cv') }}
                                            </label>
                                            <input disabled v-model="user.cv" >
                                        </div>
                                        <div class="email-area mt--15 ">
                                            <label class="form-label ">
                                                <i class="feather-file-text" ></i>
                                                {{ this.$t('user_profile_page.fields.cin') }}
                                            </label>
                                            <input disabled v-model="user.cin" >
                                        </div>
                                        <div class="email-area mt--15 ">
                                            <label class="form-label ">
                                                <i class="feather-file-text"></i>
                                                {{ this.$t('user_profile_page.fields.nifstat') }}
                                            </label>
                                            <input disabled v-model="user.nifstat"  >
                                        </div>
                                    </div>
                                    <div class=" mt--30" >
                                        <div class="d-flex justify-content-between align-items-center p-3">
                                            <label class="form-label" >
                                                <i class="feather-briefcase" ></i>
                                                {{ this.$t('user_profile_page.fields.technologies') }}
                                            </label>
                                        </div>
                                        <div class="content-techno-description p-3">
                                            <div v-if="!user.technologies.length" class="replace-when-empty d-flex justify-content-center align-items-center p-4 gap-2">
                                                <i class="feather-briefcase"></i>
                                                <p>{{ this.$t('user_profile_page.display_text.empty_item') }}</p>
                                            </div>
                                            <div v-else class="technos-descriptions d-flex justify-content-start align-items-center gap-4 ps-3">
                                                <div class="techno d-flex justify-content-center align-items-center gap-2 " 
                                                v-for="techno in user.technologies" :key="techno">
                                                    <span>{{ techno }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade show active " id="nav-homes"  v-else>
                                <div class="nuron-information">
                                    <div class="profile-form-wrapper">
                                        <div class="first-name">
                                            <label class="form-label ">
                                                <i class="feather-codepen" ></i>
                                                {{ this.$t('user_profile_page.fields.company_name') }}
                                            </label>
                                            <input disabled v-model="user.company_name" >
                                        </div>
                                        <div class="email-area mt--15">
                                            <label class="form-label ">
                                                <i class="feather-at-sign" ></i>
                                                {{ this.$t('user_profile_page.fields.email') }}
                                            </label>
                                            <input disabled v-model="user.email" type="email">
                                        </div>
                                    </div>
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-globe" ></i>
                                                {{ this.$t('user_profile_page.fields.country') }}
                                            </label>
                                            <input disabled v-model="user.state" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-home" ></i>
                                                {{ this.$t('user_profile_page.fields.city') }}
                                            </label>
                                            <input disabled v-model="user.country" >
                                        </div>
                                    </div>
                                    
                                    <div class="input-two-wrapepr-prifile">
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-map" ></i>
                                                {{ this.$t('user_profile_page.fields.address') }}
                                            </label>
                                            <input disabled v-model="user.address" >
                                        </div>
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-phone" ></i>
                                                {{ this.$t('user_profile_page.fields.contact') }}
                                            </label>
                                            <input disabled v-model="user.contact" >
                                        </div>
                                    </div>
                                    
                                    <div class="profile-form-wrapper">
                                        <div class="role-area mt--15">
                                            <label class="form-label  mb--10">
                                                <i class="feather-cpu" ></i>
                                                {{ this.$t('user_profile_page.fields.rcs') }}
                                            </label>
                                            <input disabled v-model="user.rcs" >
                                        </div>
                                    </div>
                                    
                                    <div class="edit-bio-area mt--30">
                                        <label class="form-label ">
                                            <i class="feather-crosshair" ></i>
                                            {{ this.$t('user_profile_page.fields.description') }}
                                        </label>
                                        <textarea v-model="user.description" disabled ></textarea>
                                    </div>

                                    <div class="profile-form-wrapper mt--15">
                                        <div class="email-area mt--15">
                                            <label class="form-label ">
                                                <i class="feather-link" ></i>
                                                {{ this.$t('user_profile_page.fields.portfolio') }}
                                            </label>
                                            <input disabled v-model="user.portfolio" >
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row mt-3 plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0 d-flex justify-content-center">
                    <div class="col-12 d-flex justify-content-end align-items-center titre-profile">
                        <router-link to="/edit-profile">
                            <button class="btn btn-primary-alta d-flex gap-3" title="Update user profile" >
                                <i class="feather-edit"></i>
                                <span>{{ this.$t('user_profile_page.options.update') }}</span>
                            </button>
                        </router-link> 
                    </div>
                </div>
            </div>
        </div>
        

        <Teleport to="#on-loading-process">
            <OnLoading v-if="on_processing"/>
        </Teleport>
    </layout>
</template>

<script>
import Layout from "@/components/layouts/Layout"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb"
import OnLoading from "@/components/popups/OnLoading"
import store from '../store'
import { updateUser } from "../services/users_services"

import BACKEND_URL_BASE_API from "@/services/api_url_base"

export default {
    name: 'EditProfilePage',
    components: {Breadcrumb, Layout, OnLoading},
    data(){
        return {
            backend_url: BACKEND_URL_BASE_API,
            url_image: null,
            user: {
                email: '',
                type: '',
                image: null,
                portfolio: '',
                sanction: false,
                password: '',
                city: '',
                first_name: '',
                last_name: '',
                address: '',
                contact: '',
                cv: null,
                cin: null,
                nifstat: null,
                technologies: [],
                company_name: '',
                rcs: '',
                state: '',
                country: '',
                activity: '',
                description: '',
            }
        }
    },
    mounted() {
        const user_selected = store.state.user
        if(user_selected){
            this.user.email = user_selected.email
            this.user.type = user_selected.type
            this.user.image = user_selected.image
            this.user.portfolio = user_selected.portfolio
            this.user.password = user_selected.password
            this.url_image  = user_selected.image ? (this.backend_url + '/' + user_selected.image) : null
            console.log(this.url_image)
            if(user_selected.type === 'freelance'){
                this.user.first_name = user_selected.freelance.first_name
                this.user.last_name = user_selected.freelance.last_name
                this.user.city = user_selected.freelance.city
                this.user.address = user_selected.freelance.address
                this.user.contact = user_selected.freelance.contact
                this.user.cv = user_selected.freelance.cv
                this.user.cin = user_selected.freelance.cin
                this.user.nifstat = user_selected.freelance.nifstat
                try {
                    this.user.technologies = user_selected.freelance.technologies? user_selected.freelance.technologies : []
                } catch (error) {
                    console.error("Failed to parse technologies:", error)
                }
            }
            else{
                this.user.company_name = user_selected.client.company_name
                this.user.rcs = user_selected.client.rcs
                this.user.city = user_selected.client.city
                this.user.state = user_selected.client.state
                this.user.country = user_selected.client.country
                this.user.activity = user_selected.client.activity
                this.user.description = user_selected.client.description
            }
        }
    },
}
</script>

<style lang="scss" >
i{
    user-select: none;
}
.titre-profile{
    height: 5rem;
    h4{
        margin: 0;
    }
}
input:disabled,
textarea:disabled{
    border-width: 0 0 1px 0 !important;
    background: none !important;
    border-radius: 0px !important;
    user-select: none !important;
    padding: 0 !important;
    letter-spacing: 1.5px;
}
.form-label{
    line-height: 1;
    i{
        font-size: small;
    }
}
</style>