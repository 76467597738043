<template>
    <layout>
        <div class="rn-not-found-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="rn-not-found-wrapper">
                            <h2 class="large-title">403</h2>
                            <h3 class="title">{{ this.$t('messages.acces_denied') }}!</h3>
                            <p>{{ this.$t('messages.acces_denied_message') }}.</p>
                            <router-link to="/" class="btn btn-primary btn-large">{{ this.$t('actions.acces_redirection') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'

    export default {
        name: 'NotFound',
        components: {Layout}
    }
</script>