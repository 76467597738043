<template>
    <layout>
        <breadcrumb 
        :title="!selected_roject? this.$t('client_project_and_create_update_project_page.display_text.create_project'): this.$t('client_project_and_create_update_project_page.display_text.update_project')" 
        :current="!selected_roject? this.$t('client_project_and_create_update_project_page.display_text.create_project') : this.$t('client_project_and_create_update_project_page.display_text.update_project')"
        />

        <div class="create-area">
            <div class="container">
                <div class="row mb-5 mt-5">
                    <div class="d-flex" >
                        <router-link to="/client-projects" class="back-link" >
                            <button class="btn btn-primary-alta d-flex gap-2">
                                <i class="feather-arrow-left"></i>
                                <span>{{ this.$t('client_project_and_create_update_project_page.actions.back') }}</span>
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-lg-12 mb-3">
                        <div class="form-wrapper-one">
                            <form class="row" @submit.prevent="createProject">
                                <div class="col-md-12">
                                    <div class="input-box pb--20" >
                                        <label for="project_name" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.project_name') }}
                                        </label>
                                        <input :class="{'check-valide': error_project_name}" v-model="project.project_name" type="text" id="project_name" :placeholder="this.$t('client_project_and_create_update_project_page.fields.placeholder_text')" @blur="has_checked===true?checkValidation:null" >
                                        <span class="error-message" v-if="error_project_name" > {{ error_messages.project_name }} </span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-box pb--20">
                                        <label for="description" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.description') }}
                                        </label>
                                        <textarea
                                            v-model="project.description"
                                            :class="{'check-valide': error_description}"
                                            id="description"
                                            rows="3"
                                            @blur="has_checked?checkValidation:null"
                                            :placeholder="this.$t('client_project_and_create_update_project_page.fields.placeholder_text')">
                                        </textarea>
                                        <span class="error-message" v-if="error_description" > {{ error_messages.description }} </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-box pb--20">
                                        <label for="requested_freelancer_count" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.freelances_count') }}
                                        </label>
                                        <input :class="{'check-valide': error_requested_freelancer_count}" v-model="project.requested_freelancer_count" type="number" id="requested_freelancer_count" :placeholder="this.$t('client_project_and_create_update_project_page.fields.placeholder_text')"  @blur="has_checked?checkValidation:null">
                                        <span class="error-message" v-if="error_requested_freelancer_count" > {{ error_messages.requested_freelancer_count }} </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-box pb--20">
                                        <label for="start_date" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.start_date') }}
                                        </label>
                                        <input :class="{'check-valide': error_start_date}" v-model="project.start_date" type="date" id="start_date"  @blur="has_checked?checkValidation:null">
                                        <span class="error-message" v-if="error_start_date" > {{ error_messages.start_date }} </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-box pb--20">
                                        <label for="end_date" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.end_date') }}
                                        </label>
                                        <input :class="{'check-valide': error_end_date}" v-model="project.end_date" type="date" id="end_date"  @blur="has_checked?checkValidation:null">
                                        <span class="error-message" v-if="error_end_date" > {{ error_messages.end_date }} </span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-box pb--20">
                                        <label for="budget" class="form-label">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.budget') }} ($)
                                        </label>
                                        <input :class="{'check-valide': error_budget}" v-model="project.budget" type="number" id="budget" :placeholder="this.$t('client_project_and_create_update_project_page.fields.placeholder_text')" @blur="has_checked?checkValidation:null">
                                        <span class="error-message" v-if="error_budget" > {{ error_messages.budget }} </span>
                                    </div>
                                </div>
                                <p class="mb-2">{{ this.$t('client_project_and_create_update_project_page.fields.payment_type') }}</p>
                                <div class="row mt-2 mb-1 d-flex align-items-center pt-2 pb-2 " :class="{'check-valide': error_payment_type}" >
                                    <div class="col-6">
                                        <input v-model="project.payment_type" value="forfaitaire" type="radio"  id="forfaitaire"  @change="has_checked?checkValidation:null">
                                        <label class="rn-check-box-label" for="forfaitaire">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.payment_fixed') }}
                                        </label>
                                    </div>
                                    <div class="col-6">
                                        <input v-model="project.payment_type" value="mensuel" type="radio"  id="mensuel"  @change="has_checked?checkValidation:null">
                                        <label class="rn-check-box-label" for="mensuel">
                                            {{ this.$t('client_project_and_create_update_project_page.fields.payment_monthly') }}
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <span class="error-message" v-if="error_payment_type" >{{ this.$t('mixins.validation.select_item') }}</span>
                                </div>
                                <div class="col-6">
                                    <div class="input-box">
                                        <button type="submit" class="btn btn-primary w-100">
                                            {{ !selected_roject? this.$t('client_project_and_create_update_project_page.actions.create_project') : this.$t('client_project_and_create_update_project_page.actions.save_change') }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Teleport to="#on-loading-process">
            <OnLoading v-if="on_processing===true"/>
        </Teleport>

    </layout>
</template>

<script>
import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import OnLoading from "@/components/popups/OnLoading.vue"

import { createProject } from '../services/project_sercive'
import store from '../store'

import FieldsMethodValidator from '../mixins/FieldsMethodValidator'

export default {
    name: 'CreateProject',
    components: { Breadcrumb, Layout, OnLoading},
    mixins: [ FieldsMethodValidator ],
    data() {
        return {
            has_checked: false,
            error_messages: {
                project_name: '',
                description: '',
                budget: '',
                requested_freelancer_count: '',
                payment_type: '',
                start_date: '',
                end_date: '',
            },
            error_project_name: false,
            error_description: false,
            error_budget: false,
            error_requested_freelancer_count: false,
            error_payment_type: false,
            error_start_date: false,
            error_end_date: false,
            project: {
                project_name: '',
                description: '',
                budget: '',
                requested_freelancer_count: '',
                payment_type: false,
                start_date: null,
                end_date: null,
            },
            on_processing: false,
            selected_roject: null,
        }
    },
    methods: {
        projectSattus(){
            if(this.project.project_name && this.project.budget && this.project.description && this.project.end_date && this.project.requested_freelancer_count && this.project.payment_type && this.project.start_date){
                return true
            }else{
                return false
            }
        },
        async createProject(){
            this.has_checked = true
            if(this.checkValidation()===false){
                this.on_processing = true
                try{
                    const response = await createProject(this.project)
                    if(response.status === 201){
                        this.$router.push('client-projects')
                    }
                }catch(error){
                    console.log(error)
                }finally{
                    this.on_processing = false
                }
            }
        },
        checkValidation(){
            this.error_project_name = !this.valideFieldsRegex('project', 'project_name', /^[a-zA-Z0-9 ',éèçàâêîôïäëö]+$/)
            this.error_description = !this.valideFieldsRegex('project', 'description', /^[a-zA-Z0-9 ',éèçàâêîôïäëö]+$/)
            this.error_requested_freelancer_count = !this.valideFieldsRegex('project', 'requested_freelancer_count', /^\d+$/)
            this.error_start_date = !this.valideFieldsLength('project', 'start_date')
            this.error_end_date = !this.valideFieldsLength('project', 'end_date')
            this.error_budget = !this.valideFieldsRegex('project', 'budget', /^\d+$/)
            this.error_payment_type = !this.valideFieldsLength('project', 'payment_type')
            return (this.error_budget || this.error_description || this.error_end_date || this.error_payment_type || this.error_project_name || this.error_requested_freelancer_count || this.error_start_date)
        },
        convertDateFormat(date_to_format){
            const date = new Date(date_to_format)
            const formated_date = date.toISOString().split('T')[0]
            return formated_date
        }
    },
    mounted(){
        this.selected_roject = store.state.projectSelected
        if(this.selected_roject){
            this.project = this.selected_roject
            this.project.start_date = this.convertDateFormat(this.selected_roject.start_date)
            this.project.end_date = this.convertDateFormat(this.selected_roject.end_date)
        }
    },
    watch : {
        '$i18n.locale'(locale){
            this.checkValidation()
        }
    }
}
</script>
<style lang="scss" >
.back-link{
    width: fit-content !important;
}
.check-valide{
    border: 1px solid #c43c3c !important;
}
span.error-message{
    color: #dd5454;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-weight: 400;
}
</style>