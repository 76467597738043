<template>
    <form ref="form" class="rwt-dynamic-form" id="contact-form">
        <!-- MESSAGE -->
        <div class="mb-5">
            <label for="user_message" class="form-label">{{ this.$t('submit_project_page.fields.describe_your_ambition') }}</label>
            <textarea name="user_message" id="user_message" rows="3" v-model="formData.user_message"></textarea>
            <span v-if="v$.formData.user_message.$error" class="text-danger mt-2 d-inline-block">
                {{ v$.formData.user_message.$errors[0].$message }}
            </span>
        </div>
        
        <!-- START DATE -->
        <div class="mb-5">
            <label for="date_start" class="form-label">{{ this.$t('submit_project_page.fields.when_to_start') }}</label>
            <input id="date_start" name="date_start" type="text" v-model="formData.date_start">
        </div>

        <div class="row mb-5">
            <!-- NAME -->
            <div class="col-md-6">
                <label for="user_name" class="form-label">{{ this.$t('submit_project_page.fields.your_name') }}</label>
                <input name="user_name" id="user_name" type="text" v-model="formData.user_name">
                <span v-if="v$.formData.user_name.$error" class="text-danger mt-2 d-inline-block">
                    {{ v$.formData.user_name.$errors[0].$message }}
                </span>
            </div>
            <!-- EMAIL -->
            <div class="col-md-6">
                <label for="user_email" class="form-label">{{ this.$t('submit_project_page.fields.your_email') }}</label>
                <input id="user_email" name="user_email" type="email" v-model="formData.user_email">
                <span v-if="v$.formData.user_email.$error" class="text-danger mt-2 d-inline-block">
                    {{ v$.formData.user_email.$errors[0].$message }}
                </span>
            </div>
        </div>

        <button name="submit" type="submit" class="btn btn-primary" @click.prevent="submitForm">
            {{ this.$t('submit_project_page.actions.find_gem') }}
        </button>
        <p v-if="submitted" class="mt-4 font-14 text-success">Message envoyé! Onexus vous remercie de votre confiance</p>
    </form>
</template>

<script>
    import axios from 'axios'
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'SubmitProjectForm',
        data() {
            return {
                v$: useVuelidate(),
                formData: {
                    user_name: '',
                    user_email: '',
                    date_start:'',
                    user_message: ''
                },
                submitted: false
            }
        },
        validations() {
            return {
                formData: {
                    user_name: {required},
                    user_email: {required, email},
                    user_message: {required}
                }
            }
        },
        methods: {
            submitForm() {
                this.v$.$validate();
                const data = {
                    subject: 'Dépot de projet',
                    name: this.formData.user_name,
                    email: this.formData.user_email,
                    message:this.formData.user_message,
                    date_start:this.formData.date_start

                };

                if (!this.v$.$error) {
                    axios({
                        method: 'post',
                        url: 'https://api.onexus.tech/api/sendmail',
                        data,
                    }).then((_res) => {
                        this.submitted = true;
                        this.formData.user_name = '';
                        this.formData.user_email = '';
                        this.formData.user_message = ''
                        alert('Message envoyé')
                        this.v$.$reset()
                    }).catch((err) => {
                        console.log(err)
                    });
                } else {
                    console.log('Please fill up the required fields')
                }
            }
        }
    }
</script>