<template>
    <layout>
        <breadcrumb title="Une sélection de freelances" current="Une sélection de freelances"/>

        <explore-filter-freelance :ref="filter_freelances"/>

        <Teleport to="#on-loading-process">
            <OnLoading v-if="$refs.filter_freelances?.onProcessing===true"/>
        </Teleport>

    </layout>
</template>

<script>

import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
import ExploreFilterFreelance from '@/components/explore/ExploreFilterFreelance'
import OnLoading from '@/components/popups/OnLoading'

export default {
    name: 'FreelancerFilter',
    components: { Breadcrumb, Layout, ExploreFilterFreelance, OnLoading},
    mixins: [SalScrollAnimationMixin]
}
</script>