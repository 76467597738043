<template>
    <layout>
        <!-- Start Banner Area -->
        <div class="slider-one rn-section-gapTop">
            <div class="container">
                <div class="row row-reverce-sm align-items-center">
                    <div class="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
                        <span>
                            <p >
                                Startup <span><img style="width:2.5rem" :src="require('@/assets/images/import/french_flag.webp')"></span>  early-stage ? 
                            </p>
                            
                            <!-- CSS A METTRE -->
                            <h2 style="font-size: 49px;" class="title" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                                <!-- Propulsez votre projet sans exploser votre budget 🚀 -->
                                <!-- Le repère pour trouver ses freelances techs -->
                               <span>{{this.$t('home_page_onexus.display_text.reduce_costs') }}</span>
                               <!-- <img style="width: 5rem" :src="require('@/assets/images/import/madagascar_flag2.svg')"/> -->
                            </h2>
                            <p class="mb-5">
                                <span>{{this.$t('home_page_onexus.display_text.top_engineers') }}</span>
                            </p>
                        </span>
                        <div class="button-group">
                            <!-- <router-link class="btn btn-large btn-primary" to="/findFreelancer" target="_blank" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">
                               Trouver un freelance
                            </router-link> -->
                            
                            <!-- <router-link  to="" target="_blank" data-sal-delay="500" data-sal="slide-up" data-sal-duration="800"> -->
                                <a class="btn btn-large btn-primary" href="https://calendar.app.google/cR1CSqq4w3zD32Us6" target="_blank">{{ this.$t('actions.book_slot') }}</a>
                            <!-- </router-link> -->
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
                        <div class="slider-thumbnail position-relative computer">
                            <img class="image_1 position-absolute" :src="require(`@/assets/images/import/3D3.gif`)" alt="Slider Images">
                            <img class="image_2 position-absolute" :src="require(`@/assets/images/import/cv_1.png`)" alt="Slider Images">
                            <img class="image_3 position-absolute" :src="require(`@/assets/images/import/cv_3.png`)" alt="Slider Images">
                            <img class="image_4 position-absolute" :src="require(`@/assets/images/import/cv_2.png`)" alt="Slider Images">
                        </div>
                        <div class="slider-thumbnail position-relative mobile">
                            <img class="image_1" :src="require(`@/assets/images/import/3D3.gif`)" alt="Slider Images">
                            <img class="image_5 position-absolute" :src="require(`@/assets/images/import/cv_1.png`)" alt="Slider Images">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Banner Area -->

        <!-- FREELANCER LIST -->
        <FreelancerList/>

        <!-- LANCER SON PROJET-->
        <Slide1 />

        <!-- PRICE COMPARISON TJM -->
        <ComparisonOnexus />
        
        <!-- CALL TO ACTION 2 -->
        <CallToAction2 />
        
        <!-- DESCRIPTION 1  -->
        <Description1 />
        
        <!-- CALL TO ACTION 1 -- nous sommes là pour vous aider -->
        <CallToAction1 />

        <!-- PROJECTS LIST -->
        <!-- <ProjectsList/> -->
        
        <share-modal/>

        <report-modal/>
    </layout>
</template>

<script>
    import Layout from '../../components/layouts/Layout'
    import FreelancerList from '@/components/onexus/FreelancerList.vue'
    import ServiceCard from '@/components/service/ServiceCard'
    import ProductCard from '@/components/product/ProductCard'
    import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
    import ShareModal from '@/components/modal/ShareModal'
    import ReportModal from '@/components/modal/ReportModal'
    import ExploreFilter from '@/components/explore/ExploreFilter'
    import ServiceStyleOne from '@/components/service/ServiceStyleOne'
    import SellerStyleOne from '@/components/seller/SellerStyleOne'
    import CollectionStyleOne from '@/components/collection/CollectionStyleOne'
    import ExploreNewestItem from '@/components/explore/ExploreNewestItem'
    import Description1 from '../../components/onexus/Description1.vue'
    import Typewriter from 'typewriter-effect/dist/core'
    import Slide1 from '@/components/onexus/Slide1.vue'
    import CallToAction1 from '@/components/onexus/CallToAction1.vue'
    import CallToAction2 from '@/components/onexus/CallToAction2.vue'
    // import ProjectsList from '../../components/onexus/ProjectsList.vue'

    // PRICE COMPARISON
    import ComparisonOnexus from '@/components/onexus/ComparisonOnexus.vue'

    export default {
        name: 'HomeOnexus',
        components: {
            FreelancerList,
            Description1,
            ExploreNewestItem,
            CollectionStyleOne,
            SellerStyleOne,
            ServiceStyleOne,
            ExploreFilter,
            ReportModal,
            ShareModal,
            ProductCard,
            ServiceCard,
            Layout,
            Slide1,
            ComparisonOnexus,
            CallToAction1,
            CallToAction2,
            // ProjectsList
        },
        mixins: [SalScrollAnimationMixin],
        data() {
            return {
                message: "Fondateur",
                description_key: 0
            }
        },
        method: {
            // fondateur_1 () {
            //     this.message = "fondateur"
            // }
            // fondateur_2 () {
            //     this.message = "visionnaire"
            // }
        },
        mounted() {
            
            // new Typewriter('#typewriter', {
            // strings: ['Fondateurs', 'Visionnaires'],
            // autoStart: true,
            // loop: true,
            // });
        }
    }
</script>

<style scoped>
.fondateur{
    background: #3165bf;
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
    /* min-width: 37rem; */
    display: inline-block;
}

.slider-thumbnail .image_3D {
    width: 50rem;
}
.slider-one.rn-section-gapTop {
    margin-bottom:15rem
}

/* ANIMATION HERO */
.image_1{
    left: 4rem;
    top: -26rem;
    width:51rem !important;
}
.image_2{
    top: -25rem;
  right: 39rem;
  width: 22rem !important;
  animation: float 4s ease-in-out infinite;
}
.image_3{
    top: -5rem;
  right: -15rem;
  width: 22rem !important;
  animation: float 6s ease-in-out infinite;
}
.image_4{
    top: -25rem;
  right: -15rem;
  width: 20rem !important;
  animation: float 5s ease-in-out infinite;
}

/* mobile version */
.image_5{
    top: -5rem;
    right: 32rem;
    width: 22rem !important;
    animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  } 
}
</style>