import {createRouter, createWebHistory} from 'vue-router'
import HomeOnexus from '../views/home/HomeOnexus'
import FreelancerFilter from '../views/FreelancerFilter'
import Creators from '../views/Creators'
import About from '../views/About'
import Contact from '../views/Contact'
import SubmitProject from '../views/SubmitProject'
import FindFreelancer from '../views/FindFreelancer'
import UpcomingProjects from '../views/UpcomingProjects'
import Login from '../views/Login'
import Signup from '../views/Signup'
import ForgetPassword from '../views/ForgetPassword'
import PrivacyPolicy from '../views/PrivacyPolicy'
import NotFound from '../views/NotFound'
import Feathers from '../views/feathers/Feathers'
import UserProfile from '../views/UserProfile'
import CreateProject from '../views/CreateProject.vue'
import ClientProjects from '../views/ClientProjects.vue'
import FreelanceProject from '../views/FreelanceProject.vue'
import Denied from '../views/Denied.vue'
import Mine from '../views/Mine.vue'
import UserProfileEdit from '../views/UserProfileEdit.vue'
import ProjectDetails from '../views/ProjectDetails.vue'
import ExploreFourteen from '../views/ExploreFourteen.vue'

const routes = [
    {
        path: '/',
        name: 'HomeOnexus',
        component: HomeOnexus,
        meta: {
            title: 'Onexus',
            description: 'Onexus, the place to quick hire globla talent and ease remote work.'
        },
    },
    {
        path: '/freelancer-filter',
        name: 'FreelancerFilter',
        component: FreelancerFilter,
        meta: {
            title: 'Onexus',
        },
    },
    {
        path: '/create-project',
        name: 'CreateProJect',
        component: CreateProject,
        meta: {
            title: 'Create project || Onexus',
        },
    },
    {
        path: '/project-details',
        name: 'ProjectDetails',
        component: ProjectDetails,
        meta: {
            title: 'Project details || Onexus',
        },
    },
    // {
    //     path: '/creator',
    //     name: 'Creators',
    //     component: Creators,
    //     meta: {
    //         title: 'Collection || Onexus',
    //     },
    // },
    // {
    //     path: '/about',
    //     name: 'About',
    //     component: About,
    //     meta: {
    //         title: 'Onexus',
    //     },
    // },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'Contact || Onexus',
        },
    },
    {
        path: '/submit-project',
        name: 'SubmitProject',
        component: SubmitProject,
        meta: {
            title: 'Déposez votre projet || Onexus',
        },
    },
    {
        path: '/find-freelancer',
        name: 'FindFreelancer',
        component: FindFreelancer,
        meta: {
            title: 'Find freelances || Onexus',
        },
    },
    {
        path: '/freelance-project',
        name: 'FreelanceProject',
        component: FreelanceProject,
        meta: {
            title: 'Freelance projects || Onexus',
        },
    },
    {
        path: '/explore-14',
        name: 'ExploreFourteen',
        component: ExploreFourteen,
        meta: {
            title: 'Explore Filter Left || Onexus',
        },
    },
    {
        path: '/upcoming-projects',
        name: 'UpcomingProjects',
        component: UpcomingProjects,
        meta: {
            title: 'Upcoming Projects || Onexus',
        },
    },
    {
        path: '/client-projects',
        name: 'ClientProject',
        component: ClientProjects,
        meta: {
            title: 'Client\'s Projects || Onexus',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login || Onexus',
        },
    },
    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup,
        meta: {
            title: 'Signup || Onexus',
        },
    },
    // {
    //     path: '/forget',
    //     name: 'ForgetPassword',
    //     component: ForgetPassword,
    //     meta: {
    //         title: 'Forget Password || Onexus',
    //     },
    // },
    // {
    //     path: '/privacy-policy',
    //     name: 'PrivacyPolicy',
    //     component: PrivacyPolicy,
    //     meta: {
    //         title: 'Privacy Policy || Onexus',
    //     },
    // },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: '404 || Onexus',
        },
    },
    {
        path: '/403',
        name: 'Forbiden',
        component: Denied,
        meta: {
            title: '403 || Onexus',
        },
    },
    {
        path: '/feather',
        name: 'Feathers',
        component: Feathers,
        meta: {
            title: 'Onexus | Feathers Icons',
        },
    },
    {
        path: '/user-profile',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
            title: 'User Profile || Onexus',
        },
    },
    {
        path: '/edit-profile',
        name: 'UserProfileEdit',
        component: UserProfileEdit,
        meta: {
            title: 'Edit User Profile || Onexus',
        },
    },
    {
        path: '/mine',
        name: 'Mine',
        component: Mine,
        meta: {
            title: 'Mine || Onexus',
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
