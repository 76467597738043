import { backendApi } from './api_config'

/**
 * @param user : The user provides these informations: fisrtName, lastName (optional), email, and password to subscribe.
 * @returns It returns the response of the subscription if it was succeed, and error if there were any error during the subscription.
 */
export const signupUser = async (user) => {
    try {
        const response = await backendApi.post('/users/signup', user)
        return response
    } catch (error) {
        return error.response
    }
}

/**
 * @param user : The user provides these informations: email, and password to log in.
 * @returns It returns the user's informations if the log in was succeed, and error if there were any error.
 */
export const loginUser = async (user) => {
    try {
        const response = await backendApi.post('/users/login', user)
        return response
    } catch (error) {
        return error.response
    }
}

/**
 * @returns It returns the user's information: firstName, lastName, email, password, image (url), type of user. In addition, if the type of user id {freelance}, the additional information are: technologies, cv, and nifstat; else if the user's type is {entreprise}, the additional information is the nifstat only; and lastly if the type of user is {admin}, the additional information is the role.
 */
export const getUser = async () => {
    try {
        const response = await backendApi.get('/users')
        return response
    } catch (error) {
        return error.response
    }
}

/**
 * 
 * @param user: The user can provide all of his informations such as firstName, lastName, email, password, image. For the additional informations, the freelance user could provide the technologies, the cv and the nifstat, the entreprise user could provide the nifstat only and the admin user could provide his role. 
 * @returns It returns the user with the his new informations if the process was success but error if not.
 */
export const updateUser = async (user) => {
    try{
        const response = await backendApi.put('/users', user,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        console.log(user)
        return response
    }catch(error){
        return error.response
    }
}
