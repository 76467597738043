<template>
    <layout>
        <!-- <breadcrumb title="Projets du freelance" current="Projets du freelance"/> -->
        <div class="row message-global-container" >
            <div class="col-2 d-flex justify-content-start flex-column position-sticky options-message">
                <div class="nuron-expo-filter-widget widget-shortby">
                    <div class="inner">
                        <h5 class="widget-title">Sort By</h5>
                        <div class="content">
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nuron-expo-filter-widget widget-shortby">
                    <div class="inner">
                        <h5 class="widget-title">Sort By</h5>
                        <div class="content">
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                            <div class="nuron-form-check">
                                <button class="btn btn-primary-alta w-100" >
                                    Join
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7 d-flex justify-content-start flex-column content-message">
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
                <h5>Option message</h5>
            </div>
            <div class="col-3 d-flex justify-content-start flex-column options-message">
                <h5>This is the title</h5>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/Layout'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
export default {
    name: 'Mine',
    components: {Layout,Breadcrumb}
}
</script>

<style lang="scss" >
.message-global-container{
    .options-message{
        height: 75vh;
        overflow: none;
    }
    .content-message{
        min-height: 75vh;
        border: solid #6781885e;
        border-width: 0 1px;
    }
}
</style>