<template>
    <div>
        <!-- Start Copyright Area -->
        <div class="copy-right-one ptb--20 bg-color--1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-left flex justify-content-between">
                            <span>©2024 Onexus.</span>
                            <ul class="privacy">
                                <li>
                                    <a href="/docs/CGU.pdf" attributes-list download="CGU"> CGU </a>  
                                </li>
                                <li>
                                    <router-link to="/contact" target="_blank">Contact</router-link>
                                </li>
                                <li>
                                    <router-link to="/submit-project" target="_blank">{{ this.$t('actions.submit_project') }}</router-link>
                                </li>
                                <!-- <li>
                                    <router-link to="/find-freelancer" target="_blank">{{ this.$t('actions.find_freelancer') }}</router-link>
                                </li> -->
                                <!-- <li>
                                    <a href="https://docs.onexus.tech" target="_blank">Documentation</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-right">
                            <social-links/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Copyright Area -->

        <div class="mouse-cursor cursor-outer"/>
        <div class="mouse-cursor cursor-inner"/>

        <!-- Start Top To Bottom Area  -->
        <!-- <back-to-top @click="scrollTop"/> -->
        <!-- End Top To Bottom Area  -->
    </div>
</template>

<script>
    import BackToTop from '@/components/layouts/footer/BackToTop'
    import Logo from "@/components/logo/Logo";
    import SocialLinks from "@/components/social/SocialLinks";

    export default {
        name: 'Footer',
        components: {SocialLinks, Logo, BackToTop},
        props: {
            data: {
                default: null
            }
        },
        methods: {
            scrollTop() {
                window.scrollTo(0, 0);
            }
        }
    }
</script>