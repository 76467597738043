import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { io } from 'socket.io-client'
import i18n from './i18n'
import 'animate.css'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import './assets/scss/onexus.scss'
import 'bootstrap'

// const socket = io('http://localhost:5978')
const app = createApp(App)

// BRIDGE BETWEEN FRONT AND BACK IN REALTIME
// app.config.globalProperties.$socket = socket

app.use(store)
app.use(router)

// MULTILINGUE
app.use(i18n)
app.mount('#app')

// store.dispatch('checkAuthentication').then(() => {
//     app.mount('#app')
// })
