<template>
    <div class="rn-live-bidding-area rn-section-gapTop">
        <div class="container" >
            <carousel class="portfolio-slider"
                      :items-to-show="1"
                      :wrap-around="true"
                      :breakpoints="breakpoints">
                <template #addons>
                    <div class="carousel-header">
                        <div class="section-title">
                            <h3 class="title mb--0 live-bidding-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                {{ this.$t('home_page_onexus.display_text.among_freelancers') }}
                            </h3>
                        </div>
                        <div class="carousel-navigation">
                            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15"> -->
                            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt_mobile--15">
                                    <div class="view-more-btn text-start text-sm-end">
                                        <router-link class="btn-transparent" to="/FreelancerFilter" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            VOIR TOUT
                                        </router-link>
                                    </div>
                                </div> -->
                            <navigation/>
                        </div>
                    </div>
                </template>

                <slide v-for="(freelancer, index) in freelancersList" :key="index"> 
                    <freelancer-card
                    :freelancer = "freelancer"
                    :show-place-bid="showPlaceBid"
                    product-style-class="no-overlay with-placeBid"
                    />
                </slide>
            
            </carousel>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import FreelancerCard from '@/components/onexus/FreelancerCard'
import { airtableApi } from '../../services/api_config'

export default {
    name: 'FreelancerList',
    components: {
        FreelancerCard,
        Carousel,
        Slide,
        Navigation,
    },
    props: {
        title: {
            type: String,
            default: 'Live Bidding'
        },
        showPlaceBid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            freelancersList:[],
            freelancerListReady: false,
            breakpoints: {
                576: {
                    itemsToShow: 2,
                    snapAlign: 'left'
                },
                768: {
                    itemsToShow: 2,
                    snapAlign: 'left'
                },
                992: {
                    itemsToShow: 3,
                    snapAlign: 'left'
                },
                1200: {
                    itemsToShow: 4,
                    snapAlign: 'left'
                },
                1399: {
                    itemsToShow: 5,
                    snapAlign: 'left'
                }
            },
        }
    },
    methods: {
        getFreelancers() {
            airtableApi.get(`/v0/appRVLOFnXG9wPeFh/tbl9nXBypW3Ox7SlD`)
            .then(resGetFreelancer => {
                this.freelancersList = resGetFreelancer.data.records.filter(freelance => freelance.fields['A afficher'])
                this.freelancersListReserve = this.freelancersList
                this.freelancerListReady = true
                // this.getListTechno()
                // debugger
            })
            .catch(errGetFreelancer => {
                console.log(errGetFreelancer)
            })
        },
    },
    mounted(){
        this.getFreelancers()
    }
}
</script>

<style>
.rn-section-gapTop{
    margin-bottom: 8rem
}
.result-not-found{
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: .5rem;
    background: #fff;
    border-radius: .5rem;
}
.result-not-found h4{
    margin-top: 1rem;
}
</style>
