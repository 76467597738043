<template>
    <form class="rwt-dynamic-form" id="contact-form">
        <div class="mb-5">
            <label for="contact-name" class="form-label">{{ this.$t('contact_page.fields.name') }}</label>
            <input name="contact-name" id="contact-name" type="text" v-model="formData.name">
            <span v-if="v$.formData.name.$error" class="text-danger mt-2 d-inline-block">
                {{ v$.formData.name.$errors[0].$message }}
            </span>
        </div>
        <div class="mb-5">
            <label for="contact-email" class="form-label">{{ this.$t('contact_page.fields.email') }}</label>
            <input id="contact-email" name="contact-email" type="email" v-model="formData.email">
            <span v-if="v$.formData.email.$error" class="text-danger mt-2 d-inline-block">
                {{ v$.formData.email.$errors[0].$message }}
            </span>
        </div>
        <div class="mb-5">
            <label for="contact-message" class="form-label">{{ this.$t('contact_page.fields.your_message') }}</label>
            <textarea name="contact-message" id="contact-message" rows="3" v-model="formData.message"></textarea>
            <span v-if="v$.formData.message.$error" class="text-danger mt-2 d-inline-block">
                {{ v$.formData.message.$errors[0].$message }}
            </span>
        </div>
        <button name="submit" type="submit" class="btn btn-primary" @click.prevent="submitForm">{{ this.$t('contact_page.actions.send_message') }}</button>
        <p v-if="submitted" class="mt-4 font-14 text-success">Message envoyé! Onexus vous remercie de votre confiance</p>
    </form>
</template>

<script>
    import axios from 'axios'
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'ContactForm',
        data() {
            return {
                v$: useVuelidate(),
                formData: {
                    name: '',
                    email: '',
                    message: ''
                },
                submitted: false
            }
        },
        validations() {
            return {
                formData: {
                    name: {required},
                    email: {required, email},
                    message: {required}
                }
            }
        },
        methods: {
            submitForm() {
                this.v$.$validate();
                const data = {
                    subject: 'Contact',
                    name: this.formData.name,
                    email: this.formData.email,
                    message:this.formData.message

                };

                if (!this.v$.$error) {
                    axios({
                        method: 'post',
                        url: 'https://api.onexus.tech/api/sendmail',
                        data,
                    }).then((_res) => {
                        this.submitted = true;
                        this.formData.name = '';
                        this.formData.email = '';
                        this.formData.message = '';
                        alert('Message envoyé')
                        this.v$.$reset()
                    }).catch((err) => {
                        console.log(err)
                    });
                } else {
                    console.log('Please fill up the required fields')
                }
            }
        }
    }
</script>