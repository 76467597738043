<template>
    <div class="rn-slider-area">
        <div :class="`${carouselItem.image} bg_image d-flex align-items-center padding-controler-slide-product justify-content-center slide slide-style-2 fullscreen_image-banner position-relative`" data-black-overlay="5">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-12">
                        <div class="inner text-start">
                            <div class="top-bid-price d-flex">
                            </div>
                            <h2 class="title text-white theme-gradient text-center">{{ carouselItem.title }}</h2>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-sm-row align-items-center gap-5 justify-content-center">
                    <div class="">
                        <div class="place-bet-area into-banner mt_md--30 mt_sm--30">
                            <h6 class="text-center text-white">{{ this.$t('home_page_onexus.display_text.profile_in_mind') }}</h6>
                            <div class="rn-bet-create text-center">
                                <p>{{ this.$t('home_page_onexus.display_text.describe_freelancer') }}</p>
                            </div>
                            <!-- <router-link to="/findFreelancer" target="_blank">
                                <button type="button"
                                        class="btn btn-primary mt--30"
                                        data-bs-toggle="modal"
                                        data-bs-target="#placebidModal">
                                    Trouver le freelance
                                </button>           
                            </router-link> -->
                                <a class="btn btn-primary mt--30" href="https://calendar.app.google/cR1CSqq4w3zD32Us6" target="_blank">
                                    {{ this.$t('actions.book_slot') }}
                                </a>
                        </div>
                    </div>
                    <!-- <div class="">
                        <div class="place-bet-area into-banner mt_md--30 mt_sm--30">
                            <h6 class="text-center text-white">Vous ne savez pas quel profil choisir ?</h6>
                            <div class="rn-bet-create text-center">
                                <p>Partagez-nous votre projet et nous vous proposerons le profil idéal.</p>
                            </div>
                            <router-link to="/submitproject" target="_blank">
                                <button type="button"
                                        class="btn mt--30 btn-primary-alta"
                                        data-bs-toggle="modal"
                                        data-bs-target="#placebidModal">
                                    Déposer mon Projet
                                </button>           
                            </router-link>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        </div>
</template>

<script>
import {Carousel, Slide, Pagination} from 'vue3-carousel'

export default {
    name: 'Slide1',
    components: {
        Carousel
    },
    mixins: [],
    data() {
        return {
            carouselItem: 
                {
                    id: 1,
                    image: 'bg_image--21',
                    title:  this.$t('home_page_onexus.display_text.boost_project'),
                    description: ``,
                    countdown: {
                        date: '2023-11-09'
                    },
                    biddingAmount: '15',
                    lastBid: '0.244wETH',
                    name: 'Lorem Ipsum',
                }
        }
    }
}
</script>
<style scoped>
.fullscreen_image-banner{
    padding-top: 10rem !important;
    height: 34rem !important;
}
.bg_image {
    background-attachment: fixed;
}
</style>