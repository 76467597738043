export default {
    methods: {
        valideFieldsRegex(data,field, regex){
            if(this.valideFieldsLength(data,field)){
                const regex_verification = regex.test(this[data][field])
                if(regex_verification){
                    return true
                }
                // /^[\u4e00-\u9fff\s,.!?]+$/  Chines regex
                const listregex = [/^\d+$/, /^[a-zA-Z0-9 ',éèçàâêîôïäëö]+$/ , /^[a-zA-Z0-9 ']+$/ , /^[^\s@]+@[^\s@]+\.[^\s@]+$/]
                this.error_messages[field] = regex===listregex[0]? this.$t('mixins.validation.numbre_requirement') : field==='email'? this.$t('mixins.validation.invalid_email') : this.$t('mixins.validation.invalid_special_chars')
                return false
                
            }
            return false
        },

        valideFieldsLength(data,field){
            if(this[data][field]) return true
            this.error_messages[field] = this.$t('mixins.validation.field_required')
            return false
        },
        
        valideUnrequiredField(data, field, regex){
            if(this[data][field]){
                const regex_verification = regex.test(this[data][field])
                this.error_messages[field] = !regex_verification? this.$t('mixins.validation.invalid_special_chars'):null
                return regex_verification
            }
            return true
        }
    }
}